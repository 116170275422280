import React from 'react';
import {Helmet} from "react-helmet";

import ScrollComponentBase from '../components/base/ScrollComponentBase';
import LinkBase from '../components/base/LinkBase';
import AnimatedTitle from '../components/AnimatedTitle';
import FluidButton from '../components/FluidButton';

import {getBasePath} from '../utils/config';

import './NotFoundPage.scss';

class NotFoundPage extends ScrollComponentBase {

    componentWillAppear() {
        console.log("component will appear");
    }

    componentWillEnter() {
        console.log("component will enter");
    }

    componentWillLeave() {
        console.log("component will leave");
    }

    render() {
        let pageClass = "NotFoundPage";

        console.log("inside 404 render function", this.props.transitionState, this.state);

        let {language, visible} = this.state;
        let basePath = getBasePath();

        let content = {
            title: {
                fr: "Oups !",
                en: "Whoops!",
            },
            subtitle: {
                fr: "Erreur 404 — cette page n'existe pas",
                en: "404 — this page does not exist",
            },
            desc: {
                fr: "Retourner à l'accueil",
                en: "Back to home",
            },
        };

        return (
            <div className={pageClass}>
                <Helmet>
                    <title>Page 404</title>
                </Helmet>

                <div className="wrapper">

                    <div className={pageClass + "-inner"}>

                        <h1 className={pageClass + "-title"}>
                            <AnimatedTitle
                                animate={visible}
                            >
                                {content.title[language]}
                            </AnimatedTitle>
                        </h1>

                        <h2 className={pageClass + "-subtitle"}>
                            {content.subtitle[language]}
                        </h2>

                        <LinkBase
                            to={basePath + "/" + language + "/"}
                            className={pageClass + "-back-to-home"}
                        >
                            <FluidButton
                                color="#a7dde1"
                            >
                                <div
                                    dangerouslySetInnerHTML={{__html: content.desc[language]}}
                                    className={pageClass + "-content"}
                                />
                            </FluidButton>
                        </LinkBase>

                    </div>

                </div>
            </div>
        );
    }
}

export default NotFoundPage;
