import Reflux from 'reflux';

/*** STORE ***/
import {LanguageStore}    from "../../globals/LanguageStore";

class ComponentBase extends Reflux.Component {

    _mounted = false;

    constructor(props) {
        super(props);
        this.store = LanguageStore;
    }

    /*** React component lifecycle ***/

    // watch out for reflux willMount and willUnmount : https://github.com/reflux/refluxjs/tree/master/docs/components#componentwillmount-and-componentwillunmount

    componentWillMount() {
        this.willMount && this.willMount();

        super.componentWillMount();
    }

    componentDidMount() {
        this._mounted = true;

        this.didMount && this.didMount();
    }

    componentWillUpdate(nextProps, nextState) {
        this.willUpdate && this.willUpdate(nextProps, nextState);
    }

    componentDidUpdate(prevProps, prevState) {
        this.didUpdate && this.didUpdate(prevProps, prevState);
    }

    componentWillUnmount() {
        this._mounted = false;

        this.willUnmount && this.willUnmount();

        super.componentWillUnmount();
    }

    /*** Additionnal methods ***/



}

export default ComponentBase;
