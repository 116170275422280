/**
 *
 * @author Fractal-IT
 */
import React from 'react';
import {Switch, Route} from 'react-router-dom';

import ComponentBase from './components/base/ComponentBase';

/*** PAGES ***/
import HomePage from './pages/HomePage';
import ChapterPage from './pages/ChapterPage';
import ProjectPage from './pages/ProjectPage';
import TermsPage from './pages/TermsPage';
import NotFoundPage from './pages/NotFoundPage';

import {LanguageStore} from "./globals/LanguageStore";
import {TransitionStore} from "./globals/TransitionStore";

/*** COMPONENTS ***/
import { TransitionGroup, CSSTransition } from "react-transition-group";

import {getBasePath} from './utils/config';

class RoutesTransition extends ComponentBase {

    constructor(props) {
        super(props);
        this.stores = [LanguageStore, TransitionStore];
    }

    render() {

        let {language, navigationKey} = this.state;

        let basePath = getBasePath();

        return (
            <TransitionGroup>
                <CSSTransition
                    key={navigationKey}
                    timeout={1000}
                    classNames='fade'
                >
                    <div>

                        <Switch location={this.props.location}>
                            <Route
                                exact
                                path={basePath + "/" + language}
                                //component={HomePage}
                                render={(props) => <HomePage transitioning={this.state.transitioning} />}
                            />
                            <Route
                                path={basePath + "/" + language + "/informations"}
                                //component={ProjectPage}
                                render={(props) => <ProjectPage transitioning={this.state.transitioning} />}
                            />
                            <Route
                                path={basePath + "/" + language + "/:themeSlug/:chapterSlug"}
                                render={(props) => <ChapterPage chapter={props.match.params && props.match.params.chapterSlug} history={this.props.history} transitioning={this.state.transitioning} />}
                            />

                            <Route path={basePath + "/" + language + "/mentions-legales"} component={TermsPage}/>

                            <Route path="*" component={NotFoundPage} />
                        </Switch>

                    </div>

                </CSSTransition>
            </TransitionGroup>
        );
    }
}

export default RoutesTransition;
