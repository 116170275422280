export const projectData = {
    slug: "informations",
    intro: {
        title: {
            fr: "Informations",
            en: "Informations",
        },
    },

    partners: {
        title: {
            fr: "Les partenaires",
            en: "Partners",
        },
        slug: "les-partenaires",
        highlighted: [
            {
                label: "SENSEI",
                link: "https://sites.google.com/view/senseicebc",
                thumb: "/images/partners/projet-sensei-partenaire-sensei.jpg",
            },
            {
                label: "Fondation BNP Paribas",
                link: "https://group.bnpparibas/decouvrez-le-groupe/fondation-bnp-paribas",
                thumb: "/images/partners/projet-sensei-partenaire-BNPP.jpg",
            },
            {
                label: "Institut Polaire Français",
                link: "https://www.institut-polaire.fr/",
                thumb: "/images/partners/projet-sensei-partenaire-ipev.jpg",
            },
            {
                label: "Centre d'Études Biologiques de Chizé",
                link: "http://www.cebc.cnrs.fr/",
                thumb: "/images/partners/projet-sensei-partenaire-Centre-etude-biologique-chize.jpg",
            },
            {
                label: "CNRS",
                link: "http://www.cnrs.fr/",
                thumb: "/images/partners/projet-sensei-partenaire-cnrs.jpg",
            },
            {
                label: "La Rochelle Université",
                link: "https://www.univ-larochelle.fr/",
                thumb: "/images/partners/projet-sensei-partenaire-UnivLaRochelle.jpg",
            },
        ],
        regular: [
            {
                label: "Friends of Cooper Island",
                link: "http://cooperisland.org/",
                thumb: "/images/partners/projet-sensei-partenaire-Friend-of-cooper-island.jpg",
            },
            {
                label: "Woods Hole Oceanographic Institution",
                link: "https://www.whoi.edu/",
                thumb: "/images/partners/projet-sensei-partenaire-WHOI.jpg",
            },
            {
                label: "Laboratoire d'Océanographie et du Climat",
                link: "https://www.locean-ipsl.upmc.fr/index.php",
                thumb: "/images/partners/projet-sensei-partenaire-locean.jpg",
            },
            {
                label: "Norsk Polarinstitut",
                link: "https://www.npolar.no/",
                thumb: "/images/partners/projet-sensei-partenaire-NPI.jpg",
            },
            {
                label: "Norwegian University of Science and Technology",
                link: "https://www.ntnu.edu/",
                thumb: "/images/partners/projet-sensei-partenaire-NTNU.jpg",
            },
            {
                label: "Sea Mammal Research Unit",
                link: "http://www.smru.st-andrews.ac.uk/",
                thumb: "/images/partners/projet-sensei-partenaire-SMRU.jpg",
            },
            {
                label: "University of St Andrews",
                link: "https://www.st-andrews.ac.uk/",
                thumb: "/images/partners/projet-sensei-partenaire-UnivStAndrews.jpg",
            },
            {
                label: "Icebreaker - Nature Entertainment Studios",
                link: "https://www.icebreaker-studios.com/",
                thumb: "/images/partners/projet-sensei-partenaire-icebreaker.jpg",
            },
            {
                label: "Canada Research Chair in Arctic Ecology",
                link: "http://www.arcticecology.ca/research.html",
                thumb: "/images/partners/projet-sensei-partenaire-canada-artic-ecology.jpg",
            },
            {
                label: "McGill University",
                link: "https://www.mcgill.ca/",
                thumb: "/images/partners/projet-sensei-partenaire-McGill.jpg",
            },
            {
                label: "National Institute of Polar Research",
                link: "https://www.nipr.ac.jp/english/",
                thumb: "/images/partners/projet-sensei-partenaire-NIPR.jpg",
            },
            {
                label: "Kyoto University",
                link: "https://www.kyoto-u.ac.jp/en/",
                thumb: "/images/partners/projet-sensei-partenaire-kyotoUniv5.jpg",
            },
            {
                label: "LIttoral ENvironnement et Sociétés",
                link: "https://lienss.univ-larochelle.fr/",
                thumb: "/images/partners/projet-sensei-partenaire-LIENSS.jpg",
            },
            {
                label: "Institut Pluridisciplinaire Hubert Curien",
                link: "http://www.iphc.cnrs.fr/",
                thumb: "/images/partners/projet-sensei-partenaire-IPHC.jpg",
            },
            {
                label: "Zone Ateliers Antarctique",
                link: "https://zaantarctique.org/",
                thumb: "/images/partners/projet-sensei-partenaire-zata.jpg",
            },
        ],
    },

    team: {
        title: {
            fr: "L'équipe",
            en: "The team",
        },
        slug: "l-equipe",
        content: [
            {
                firstname: "Christophe",
                lastname: "Barbraud",
                thumb: "/images/team/projet-sensei-team-christophe-barbraud.jpg",
                desc: {
                    fr: "<p>Après un master et un doctorat sur les oiseaux de mer au Centre d'Etudes Biologiques de Chizé (CEBC-CNRS) et un post-doctorat à la Tour du Valat et au Patuxent Wildlife Research Center, Christophe est recruté comme chercheur en écologie au CEBC en 2001.</p><p>Il étudie l'écologie et la dynamique des populations d'oiseaux de mer en mettant l'accent sur l'impact des changements climatiques et de la pêche industrielle. Il effectue régulièrement des missions sur le terrain en Antarctique, dans les îles subantarctiques, au Pérou, au Brésil et en France. </p>",
                    en: "<p></p>",
                }
            },
            {
                firstname: "Yan",
                lastname: "Ropert-Coudert",
                thumb: "/images/team/projet-sensei-team-yan-ropert-coudert.jpg",
                desc: {
                    fr: "<p>Yan est titulaire d'un doctorat de l'Institut National de Recherche Polaire du Japon (2001), où il a été chercheur jusqu'en 2007, avant d'être recruté au CNRS à Strasbourg (2008-15) et au CEBC depuis 2015.</p><p>Il est directeur d'un programme polaire soutenu par l'Institut Polaire Français et directeur du Life Science Group, secrétaire du Groupe d'experts sur les oiseaux et les mammifères marins et membre du Comité permanent du Système du Traité de l'Antarctique du Comité Scientifique des Recherches Antarctiques.</p>",
                    en: "<p></p>",
                }
            },
            {
                firstname: "Olivier",
                lastname: "Chastel",
                thumb: "/images/team/projet-sensei-team-olivier-chastel.jpg",
                desc: {
                    fr: "<p>Après un doctorat sur les oiseaux marins au CEBC, suivi d'un post-doctorat à l'Université de Groningen, aux Pays-Bas, Olivier a été recruté au CEBC en 1996 et y est maintenant directeur de recherche et chef de l’équipe Ecophysiologie. Il est un expert des processus hormonaux, en particulier de la physiologie du stress, sous-jacents aux compromis entre les traits d’histoire de vie des oiseaux et a publié plus de 200 articles. </p><p>Olivier dirige des projets de recherche sur les conséquences physiologiques et phénotypiques de l'exposition aux contaminants chez les oiseaux de mer polaires (Svalbard, Antarctique) et tropicaux (Guyane française) et a développé un partenariat à long terme avec des instituts de recherche norvégiens sur l'étude des oiseaux de mer arctiques.</p>",
                    en: "<p></p>",
                }
            },
            {
                firstname: "Yves",
                lastname: "Cherel",
                thumb: "/images/team/projet-sensei-team-yves-cherel.jpg",
                desc: {
                    fr: "<p>Après un diplôme de l'Ecole Normale Supérieure et un brevet d'enseignement en Sciences Naturelles, Yves a effectué un hivernage sur l’île de la Possession à Crozet avant d'obtenir un doctorat sur la physiologie du jeûne chez le manchot royal. Recruté au CNRS en 1988, il s'est intéressé à l'écologie alimentaire des oiseaux et mammifères marins et, à travers eux, à l'écosystème pélagique de l'océan Austral.</p><p>Il participe régulièrement à des missions terrestres sur le terrain ou à des campagnes océanographiques dans les régions subantarctiques, antarctiques et tropicales.</p>",
                    en: "<p></p>",
                }
            },
            {
                firstname: "Christophe",
                lastname: "Guinet",
                thumb: "/images/team/projet-sensei-team-christophe-guinet.jpg",
                desc: {
                    fr: "<p>Christophe est directeur du CEBC, où il étudie l'écologie comportementale et alimentaire des mammifères marins. Il coordonne un certain nombre de programmes de recherche internationaux, parmi lesquels la contribution française au programme Southern Elephant seal as Ocean Samplers (SEaOS) et Marine Mammal Ocean Explorer- Pole to Pole (MEOP).</p><p>Grâce à une longue collaboration avec le Sea Mammal Research Unit en Ecosse, il développe la dernière génération de balises océanographiques intégrant des fluorimètres, des capteurs d'oxygène ou de lumière et des techniques de traitement des données embarquées sur les balises.</p>",
                    en: "<p></p>",
                }
            },
            {
                firstname: "Tiphaine",
                lastname: "Jeanniard du Dot",
                thumb: "/images/team/projet-sensei-team-tiphaine-jeanniard-du-dot.jpg",
                desc: {
                    fr: "<p>Tiphaine a obtenu une maîtrise des sciences et un doctorat en biologie et écologie des otaries à fourrure et des lions de mer à l'Université de Colombie-Britannique à Vancouver, au Canada. Elle s'intéresse à la compréhension des impacts des changements environnementaux sur la physiologie, l'énergétique et l'écologie des prédateurs marins apicaux en utilisant une approche multidisciplinaire.</p><p>Elle a collaboré à plusieurs projets sur l'écologie de l'alimentation des oiseaux marins, des requins et des baleines dans les écosystèmes polaires, tempérés ou tropicaux, notamment avec le ministère des Pêches et des Océans du Canada à Rimouski (Québec) sur l'écologie de l'alimentation des phoques dans l'Arctique. Tiphaine a été recrutée au CNRS en 2018 comme chercheuse au CEBC.</p>",
                    en: "<p></p>",
                }
            },
            {
                firstname: "Stéphanie",
                lastname: "Jenouvrier",
                thumb: "/images/team/projet-sensei-team-stephanie-jenouvrier.jpg",
                desc: {
                    fr: "<p>Stéphanie est scientifique associée à la Woods Hole Oceanographic Institution (USA) et au CEBC. Elle est écologiste et s'intéresse à la compréhension et à la projection des effets du changement climatique sur les traits démographiques et la dynamique des populations, en particulier des oiseaux de mer de l'océan Austral. </p><p>Ses travaux combinent des données longitudinales individuelles à long terme avec des modèles démographiques, statistiques et mathématiques et des modèles climatiques participant à l'évaluation du Groupe d’Experts Intergouvernemental sur l’Evolution du Climat.</p>",
                    en: "<p></p>",
                }
            },
            {
                firstname: "Martin",
                lastname: "Vancoppenolle",
                thumb: "/images/team/projet-sensei-team-martin-vancoppenolle.jpg",
                desc: {
                    fr: "<p>Martin étudie les liens entre la physique des glaces de mer, la biogéochimie marine et le climat à l'aide de modèles et d'observations. Au cours des 10 dernières années, il a mis au point des outils de modélisation biophysique des glaces de mer à petite et à grande échelle et des outils d'analyse de données open source. </p><p>Martin codirige le groupe de travail sur la glace de mer du NEMO, le système européen de modélisation des océans, qui a notamment servi de base aux rapports du GIEC. Martin a participé à plusieurs excursions dans les zones de glace de mer de l'Arctique et de l'Antarctique.</p>",
                    en: "<p></p>",
                }
            },
            {
                firstname: "Georges",
                lastname: "Divoky",
                thumb: "/images/team/projet-sensei-team-georges-divoky.jpg",
                desc: {
                    fr: "<p>George a commencé ses études sur les oiseaux de mer en 1970 alors qu'il était associé de recherche à la Smithsonian Institution, aux États-Unis. Il a obtenu une maîtrise sur les oiseaux de mer de l'Arctique de la Michigan State University en 1972 et un doctorat de la University of Alaska Fairbanks en 1997. Depuis 1975, il étudie les Guillemots à miroir dans le nord de l'Alaska, où il documente les changements dans la biologie de la reproduction, l'écologie alimentaire et la survie pendant une période de diminution rapide de la glace de mer en été. </p><p>Il est actuellement directeur de Friends of Cooper Island, un organisme de recherche non gouvernemental sans but lucratif qui réalise un suivi au long terme des Guillemots à miroir sur Cooper Island.</p>",
                    en: "<p></p>",
                }
            },
            {
                firstname: "Sara",
                lastname: "Labrousse",
                thumb: "/images/team/projet-sensei-team-sara-labrousse.jpg",
                desc: {
                    fr: "<p>Diplômée d’un doctorat en écologie marine antarctique en décembre 2016, Sara a réalisé sa thèse au sein du Laboratoire d’Océanographie et du Climat (LOCEAN, UPMC, Paris) et de l’Institute for Marine and Antarctic Studies (IMAS, UTAS, Hobart). Elle a étudié l’écologie alimentaire des éléphants de mer des îles Kerguelen sous la banquise antarctique en lien avec les conditions océanographiques.</p><p>Elle est à présent post-doctorante à Woods Hole Oceanographic Institution (WHOI, Boston) pour étudier l’influence de la variabilité de la banquise et des polynies cotières sur la population de manchots empereurs de Terre Adélie. Ses intérêts de recherche visent à comprendre les mécanismes impliqués dans les interactions entre les prédateurs marins, la banquise antarctique et la physique de l’océan.</p>",
                    en: "<p></p>",
                }
            },
        ],
    },

    contact: {
        title: {
            fr: "Contact",
            en: "Contact",
        },
        slug: "contact"
    },
};