import React from 'react';

import ScrollComponentBase from './base/ScrollComponentBase';
import AnimatedTitle from "./AnimatedTitle";
import ChapterThumb from './ChapterThumb';

import './ThemeSection.scss';


class ThemeSection extends ScrollComponentBase {

    static baseClassName = "ThemeSection";

    _mounted = false;

    constructor(props) {
        super(props);

        this.state = {
            hoveredThumb: 0
        };
    }

    /*** React component lifecycle ***/

    didMount() {
        this._mounted = true;
    }

    willUnmount() {
        this._mounted = false;
    }

    /*** Listeners ***/

    onScroll() {
        //console.log(this._lastScrollValue, this._scrollValue);
        //this._scrollDiff = 1 + Math.abs(this._lastScrollValue - this._scrollValue) / 100;
        //console.log(1 + Math.abs(this._lastScrollValue - this._scrollValue) / 100);
    }

    /*** Custom methods ***/

    onThumbEnter(i) {
        if(this._el && this._mounted) {
            this.setState({
                hoveredThumb: i,
            });
        }
    }

    onThumbLeave(i) {
        if(this._el && this._mounted) {
            this.setState({
                hoveredThumb: 0,
            });
        }
    }

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;

        let data = this.props.data;
        let {language, hoveredThumb} = this.state;

        return (
            <section
                className={this.getMainCSSClass()}
                ref={(el) => this.registerElement(el)}
                id={(data && data.slug) || ""}
            >
                <div className={componentClass + "-inner wrapper"}>
                    { data && data.title &&
                    <h2 className={componentClass + "-title"}>
                        <AnimatedTitle
                            animate={this.state.visible}
                        >
                            {data.title[language]}
                        </AnimatedTitle>
                    </h2>
                    }

                    { data && data.content && data.content.length > 0 &&
                        <div className={componentClass + "-thumb-list"}>
                            { data.content.map((thumb, i) =>
                                <div
                                    key={"thumb-" + i}
                                    className={hoveredThumb !== 0 && hoveredThumb !== i + 1 ? componentClass + "-thumb-list-item " + componentClass + "-thumb-list-item--not-hovered" : componentClass + "-thumb-list-item"}
                                >
                                    <ChapterThumb
                                        data={thumb}
                                        themeSlug={data.slug}
                                        thumbIndex={i + 1}
                                        onThumbEnter={(i) => this.onThumbEnter(i)}
                                        onThumbLeave={(i) => this.onThumbLeave(i)}
                                    />
                                </div>
                            )}
                        </div>
                    }
                </div>
            </section>
        );
    }
}

export default ThemeSection;
