import React from 'react';
import Reflux from "reflux";

import './CustomPointer.scss';
import {MouseStore} from "../globals/MouseStore";

class CustomPointer extends Reflux.Component {
    static baseClassName = "CustomPointer";

    constructor(props) {
        super(props);
        this.store = MouseStore;
    }

    componentWillMount() {
        super.componentWillMount();
    }

    componentDidMount() {
        this._mousePos = {
            x: -100,
            y: -100,
        };
        this._lerpedMousePos = {
            x: -100,
            y: -100,
        };

        this._ease = 0.2;

        this._mouseListener = null;
        this._raf = null;

        this._mouseListener = this._handleMouse.bind(this);
        window.addEventListener("mousemove", this._mouseListener, {passive: true});

        this._animate();
    }

    componentWillUnmount() {
        window.removeEventListener("mousemove", this._mouseListener, {passive: true});

        if(this._raf) {
            cancelAnimationFrame(this._raf);
        }

        super.componentWillUnmount();
    }

    /*** Custom methods ***/

    _lerp(start, end, ease) {
        return (1 - ease) * start + ease * end;
    }

    _animate() {
        this._lerpedMousePos = {
            x: this._lerp(this._lerpedMousePos.x, this._mousePos.x, this._ease),
            y: this._lerp(this._lerpedMousePos.y, this._mousePos.y, this._ease),
        };

        if(this._el) {
            this._el.style.left = Math.floor(this._lerpedMousePos.x * 100) / 100 + "px";
            this._el.style.top =  Math.floor(this._lerpedMousePos.y * 100) / 100 + "px";
        }

        this._raf = requestAnimationFrame(this._animate.bind(this));
    }

    _handleMouse(e) {
        this._mousePos = {
            x: e.clientX,
            y: e.clientY,
        };
    }

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;
        let mainClass = componentClass;
        if(this.state.isHovering) {
            mainClass += " " + componentClass + "--hovered";
        }

        return (
            <div
                className={mainClass}
                ref={(el) => this.registerElement(el)}
            >
                <span className={componentClass + "-pointer"} />
            </div>
        );
    }
}

export default CustomPointer;
