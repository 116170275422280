import React from 'react';
import {Helmet} from "react-helmet";

import {getBasePath, getSiteOrigin, stripTags} from "../utils/config";

import ScrollComponentBase from './base/ScrollComponentBase';
import AnimatedTitle from "./AnimatedTitle";

import {seoData} from '../data/seo.js';

import './HomeIntro.scss';

class HomeIntro extends ScrollComponentBase {

    static baseClassName = "HomeIntro";

    /*** React component lifecycle ***/

    didMount() {
        //console.log("Home intro did mount", this._scrollValue);
    }

    /*** Listeners ***/

    onScroll() {
        //console.log(this._scrollValue);
    }

    /*** Custom methods ***/

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;

        let data = this.props.data;

        let {language, visible} = this.state;

        let siteOrigin = getSiteOrigin();
        let basePath = getBasePath();

        return (
            <section
                className={this.getMainCSSClass()}
                ref={(el) => this.registerElement(el)}
            >

                <Helmet>
                    <title>{data.title[language]}</title>
                    { seoData && seoData.desc &&
                        <meta name="description" content={stripTags(seoData.desc[language])} />
                    }
                    <link rel="canonical" href={siteOrigin + basePath + "/" + language} />

                    <meta property="og:title" content={data.title[language]} />
                    {seoData && seoData.desc &&
                    <meta property="og:description" content={stripTags(seoData.desc[language])}/>
                    }
                    <meta property="og:url" content={siteOrigin + basePath + "/" + language} />

                    <meta property="og:image" content={siteOrigin + basePath + "/images/sensei-sentinels-of-the-sea-ice-socials.jpg"} />

                    <meta name="twitter:title" content={data.title[language]} />
                    {seoData && seoData.desc &&
                    <meta name="twitter:description" content={stripTags(seoData.desc[language])} />
                    }
                    <meta name="twitter:image" content={siteOrigin + basePath + "/images/sensei-sentinels-of-the-sea-ice-socials.jpg"} />
                </Helmet>

                <div className={componentClass + "-inner"}>
                    { data && data.title &&
                    <h1 className={componentClass + "-title"}>
                        <AnimatedTitle
                            animate={visible}
                        >
                            {data.title[language]}
                        </AnimatedTitle>
                    </h1>
                    }

                    { data && data.subtitle &&
                    <h2 className={componentClass + "-subtitle"}>
                        <AnimatedTitle
                            animate={visible}
                            delay={500}
                            staggering={25}
                        >
                            {data.subtitle[language]}
                        </AnimatedTitle>
                    </h2>
                    }

                </div>

                <figure className={componentClass + "-image"}>
                    <img src={basePath + "/images/sensei-sentinels-of-the-sea-ice.jpg"} alt={data.title[language]} />
                </figure>

            </section>
        );
    }
}

export default HomeIntro;
