import React from 'react';
import Reflux from 'reflux';

import {TransitionStore} from "../globals/TransitionStore";
import {TransitionActions}      from "../globals/TransitionStoreActions";

/*** DATA ***/

import {assetsData} from '../data/assets.js';

import './LoadingScreen.scss';

class LoadingScreen extends Reflux.Component {
    static baseClassName = "LoadingScreen";

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
        this._loadTimer = null;
        this._loadInterval = null;
        this._backupLoadTimer = null;

        this.store = TransitionStore;
    }

    /*** Custom methods ***/

    componentDidMount() {
        let startedLoadingTime = Date.now();

        let delay = 1000;

        let self = this;

        let webFonts = assetsData.fonts;
        let fontsLoaded = 0;
        this.waitForWebfonts(webFonts, function() {
            // Will be called as soon as ALL specified fonts are available
            fontsLoaded++;
            console.log('fonts loaded: ', fontsLoaded);
        });

        let images = assetsData.images;
        let imagesLoaded = 0;

        function onImageLoaded() {
            imagesLoaded++;
        }

        for(let i = 0; i < images.length; i++) {
            let image = new Image();
            image.crossOrigin = "anonymous";
            image.addEventListener('load', onImageLoaded);
            image.src = images[i];
        }

        this._loadInterval = setInterval(function() {
            if(fontsLoaded === webFonts.length && imagesLoaded === images.length) {
                // means we are ready
                clearInterval(self._loadInterval);
                self._loadInterval = null;

                let loadTime = Date.now();
                console.log("assets loaded in", loadTime - startedLoadingTime);

                if(loadTime - startedLoadingTime < delay) {
                    this._loadTimer = setTimeout(function() {
                        self.siteLoaded();
                    }, delay - (loadTime - startedLoadingTime));
                }
                else {
                    self.siteLoaded();
                }
            }
        }, 100);

        this._backupLoadTimer = setTimeout(function() {
            if(self.state.isLoading) {
                self.siteLoaded();
            }
        }, delay * 3);
    }

    componentWillUnmount() {
        if(this._loadTimer) {
            clearTimeout(this._loadTimer);
            this._loadTimer = null;
        }
        if(this._loadInterval) {
            clearInterval(this._loadInterval);
            this._loadInterval = null;
        }
        if(this._backupLoadTimer) {
            clearTimeout(this._backupLoadTimer);
            this._backupLoadTimer = null;
        }
    }

    siteLoaded() {
        this.setState({
            isLoading: false,
        });
        this.props.onLoadComplete && this.props.onLoadComplete();

        TransitionActions.set(false);
    }

    waitForWebfonts(fonts, callback) {
        let loadedFonts = 0;

        function loadFont(font) {
            let node = document.createElement('span');
            // Characters that vary significantly among different fonts
            node.innerHTML = 'giItT1WQy@!-/#';
            // Visible - so we can measure it - but not on the screen
            node.style.position      = 'absolute';
            node.style.left          = '-10000px';
            node.style.top           = '-10000px';
            // Large font size makes even subtle changes obvious
            node.style.fontSize      = '300px';
            // Reset any font properties
            node.style.fontFamily    = 'sans-serif';
            node.style.fontVariant   = 'normal';
            node.style.fontStyle     = 'normal';
            node.style.fontWeight    = 'normal';
            node.style.letterSpacing = '0';
            document.body.appendChild(node);

            // Remember width with no applied web font
            let width = node.offsetWidth;

            node.style.fontFamily = font;

            let interval;
            function checkFont() {
                // Compare current width with original width
                if(node && node.offsetWidth !== width) {
                    ++loadedFonts;
                    node.parentNode.removeChild(node);
                    node = null;
                }

                // If all fonts have been loaded
                if(loadedFonts >= fonts.length) {
                    if(interval) {
                        clearInterval(interval);
                    }
                    if(loadedFonts === fonts.length) {
                        callback();
                        return true;
                    }
                }
            }

            if(!checkFont()) {
                interval = setInterval(checkFont, 50);
            }
        }

        for(let i = 0, l = fonts.length; i < l; ++i) {
            loadFont(fonts[i]);
        }
    };

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;
        let mainClass = componentClass;

        if(!this.state.isLoading) {
            mainClass += " " + componentClass + "--loaded";
        }

        return (
            <div
                className={mainClass}
                ref={(el) => this.registerElement(el)}
            >
                <img src="/images/projet-sensei-logo.svg" alt="Projet SENSEI" />
            </div>
        );
    }
}

export default LoadingScreen;
