export const menuData = [
    {
        path: "",
        label: {
            fr: "Le projet SENSEI",
            en: "SENSEI PROJECT",
        },
        submenu: [
            {
                path: "presentation",
                label: {
                    fr: "Présentation",
                    en: "Introduction",
                },
            },
            {
                path: "videos",
                label: {
                    fr: "Les vidéos",
                    en: "Videos",
                },
            },
            /*{
                path: "videos",
                label: {
                    fr: "Commencer la lecture",
                    en: "Start watching",
                },
            }*/
        ],
    },
    /*{
        path: "/le-projet-sensei/un-contexte-bien-particulier",
        label: {
            fr: "Les vidéos",
            en: "Videos",
        },
    },*/
    {
        path: "informations",
        label: {
            fr: "Informations",
            en: "Informations",
        },
        submenu: [
            {
                path: "l-equipe",
                label: {
                    fr: "L'équipe",
                    en: "The team",
                },
            },
            {
                path: "les-partenaires",
                label: {
                    fr: "Les partenaires",
                    en: "Partners",
                },
            },
            {
                path: "contact",
                label: {
                    fr: "Contact",
                    en: "Contact",
                },
            },
        ],
    },
    /*{
        path: "/contact",
        label: {
            fr: "Contact",
            en: "Contact",
        },
    },*/
];