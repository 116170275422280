import React from 'react';
import {Helmet} from "react-helmet";

import {getBasePath, getSiteOrigin, stripTags} from "../utils/config";

import ScrollComponentBase from '../components/base/ScrollComponentBase';

/*** DATA ***/
import {termsData} from '../data/terms.js';
import {seoData} from '../data/seo.js';

/*** STYLES ***/
import './TermsPage.scss';
import AnimatedTitle from "../components/AnimatedTitle";

class TermsPage extends ScrollComponentBase {

    render() {
        let pageClass = "TermsPage";

        let {language, visible} = this.state;

        let siteOrigin = getSiteOrigin();
        let basePath = getBasePath();

        return (
            <div className={pageClass}>

                {termsData && termsData.intro && termsData.intro.title &&
                <Helmet>
                    <title>{termsData.intro.title[language] + " | " + seoData.title[language]}</title>
                    {termsData.intro.desc &&
                    <meta name="description" content={termsData.intro.desc[language]}/>
                    }
                    <link rel="canonical" href={siteOrigin + basePath + "/" + language + "/mentions-legales"} />

                    <meta property="og:title" content={termsData.intro.title[language] + " | " + seoData.title[language]} />
                    {termsData.intro.desc &&
                    <meta property="og:description" content={stripTags(termsData.intro.desc[language])}/>
                    }
                    <meta property="og:url" content={siteOrigin + basePath + "/" + language + "/mentions-legales"} />

                    <meta property="og:image" content={siteOrigin + basePath + "/images/sensei-sentinels-of-the-sea-ice-socials.jpg"} />

                    <meta name="twitter:title" content={termsData.intro.title[language] + " | " + seoData.title[language]} />
                    {termsData.intro.desc &&
                    <meta name="twitter:description" content={stripTags(termsData.intro.desc[language])} />
                    }
                    <meta name="twitter:image" content={siteOrigin + basePath + "/images/sensei-sentinels-of-the-sea-ice-socials.jpg"} />
                </Helmet>
                }

                <div className={pageClass + "-inner"}>
                    <div className="wrapper">
                        { termsData && termsData.intro && termsData.intro.title &&
                        <h1 className={pageClass + "-title"}>
                            <AnimatedTitle
                                animate={visible}
                            >
                                {termsData.intro.title[language]}
                            </AnimatedTitle>
                        </h1>
                        }

                        { termsData && termsData.sections && termsData.sections.length > 0 &&
                            <div className={pageClass + "-sections-list"}>
                                { termsData.sections.map((section, i) =>
                                    <section
                                        key={"section-" + i}
                                        className={pageClass + "-sections-list-item"}
                                    >
                                        { section.title &&
                                            <h2 className={pageClass + "-sections-list-item-title"}>
                                                {section.title[language]}
                                            </h2>
                                        }

                                        { section.content &&
                                        <div
                                            dangerouslySetInnerHTML={{__html: section.content[language]}}
                                            className={pageClass + "-sections-list-item-content"}
                                        />
                                        }
                                    </section>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default TermsPage;
