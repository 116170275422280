import React from 'react';
import PropTypes from 'prop-types';
import {NavLink, withRouter, matchPath} from 'react-router-dom';
import anime from 'animejs';

import ComponentBase from './base/ComponentBase';
import FluidButton from './FluidButton';

/*** STORE ***/
import {LanguageActions}      from "../globals/LanguageStoreActions";
import {LanguageStore} from "../globals/LanguageStore";

import {TransitionActions}      from "../globals/TransitionStoreActions";
import {TransitionStore} from "../globals/TransitionStore";

import {MouseActions}      from "../globals/MouseStoreActions";

import {shouldUseLanguages} from '../utils/config';

/*** DATA ***/

import {menuData} from '../data/menu.js';
import {themesData} from '../data/themes.js';

import {getBasePath} from '../utils/config';

import './Header.scss';
import ArrowIcon from "./ArrowIcon";


// analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-146165264-1');


class Header extends ComponentBase {

    static contextTypes = {
        getScrollbar: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
        this.stores = [LanguageStore, TransitionStore];

        this._transitionTimer = null;
    }

    didMount() {
        // analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        this._unlisten = this.props.history.listen((location, action) => {
            console.log("route change !", this.props.history, location, action);

            TransitionActions.set(true);

            let newLanguage = location.pathname.substring(1, 3);
            if(action === "POP" && this.state.language !== newLanguage) {
                console.log("language changed on back button!", newLanguage);
                LanguageActions.set(newLanguage);
            }

            // analytics
            ReactGA.pageview(window.location.pathname + window.location.search);

            /*var self = this;

            this._transitionTimer = setTimeout(function() {

                if(self.props.scrollbar) {
                    self.props.scrollbar.scrollTo(0, 0, 0);
                }
                else {
                    window.scrollTo(0, 0);
                }
                TransitionActions.set(false);
            }, 1000);*/
        });

        // canvas
        if(this._headerCanvas) {
            this._setupHeaderCanvas();
        }

        this._resize = this._resizeCanvas.bind(this);
        window.addEventListener("resize", this._resize);


        //TODO remove debug
        /*let self = this;
        window.addEventListener("click", function(e) {
            self._resizeCanvas();
            self._animateTransitionCanvas();
        });*/
    }

    willUpdate(nextProps, nextState) {
        if(this.props.location !== nextProps.location) {
            console.log(">>>>>>< header will update from/to", this.props.location.key, nextProps.location.key);

            let fromPage = this.props.location.pathname;
            let toPage = nextProps.location.pathname;

            TransitionActions.handleHistory(fromPage, toPage);

            let basePath = getBasePath();

            let isFromChapter =false;
            let isToChapter = false;
            let currentMatchPath = matchPath(this.props.location.pathname, {
                path: basePath + "/" + this.state.language + "/:themeSlug/:chapterSlug",
                exact: true,
                strict: false
            });

            let nextMatchPath = matchPath(nextProps.location.pathname, {
                path: basePath + "/" + this.state.language + "/:themeSlug/:chapterSlug",
                exact: true,
                strict: false
            });

            if(currentMatchPath && currentMatchPath.params && currentMatchPath.params.chapterSlug) {
                isFromChapter = true;
            }

            if(nextMatchPath && nextMatchPath.params && nextMatchPath.params.chapterSlug) {
                isToChapter = true;
            }

            if(isToChapter && !isFromChapter) {
                //this.props.onTransitioning && this.props.onTransitioning();
                //this._animateTransitionCanvas();
            }

            if(
                (!isFromChapter || !isToChapter)
                && fromPage !== toPage
                && this.state.language === nextState.language
            ) {
                console.log("ENABLE TRANSITION !!!", this.state.language, nextState.language);
                TransitionActions.setKey(this.props.location.key);


                this._animateTransitionCanvas();


                var self = this;

                this._transitionTimer = setTimeout(function() {

                    if(self.props.scrollbar && self._mounted) {
                        self.props.scrollbar.setPosition(0, 0);
                    }
                    else if(self._mounted) {
                        window.scrollTo(0, 0);
                    }
                    TransitionActions.set(false);
                }, 1000);
            }
            else {
                console.log("CANCEL TRANSITION !!!");
                TransitionActions.set(false);
            }
        }
    }

    /*didUpdate(prevProps, prevState) {
        if(this.props.location !== prevProps.location) {
            console.log(">>>>>>< header did update from/to", prevProps.location.key, this.props.location.key);

            let fromPage = prevProps.location.pathname;
            let toPage = this.props.location.pathname;

            TransitionActions.handleHistory(fromPage, toPage);

            let isFromChapter =false;
            let isToChapter = false;
            let currentMatchPath = matchPath(prevProps.location.pathname, {
                path: "/" + this.state.language + "/theme/:chapterSlug",
                exact: true,
                strict: false
            });

            let nextMatchPath = matchPath(this.props.location.pathname, {
                path: "/" + this.state.language + "/theme/:chapterSlug",
                exact: true,
                strict: false
            });

            console.log(">>> current match path", currentMatchPath);
            console.log(">>> next match path", nextMatchPath);

            if(currentMatchPath && currentMatchPath.params && currentMatchPath.params.chapterSlug) {
                isFromChapter = true;
            }

            if(nextMatchPath && nextMatchPath.params && nextMatchPath.params.chapterSlug) {
                isToChapter = true;
            }

            if(isToChapter && !isFromChapter) {
                this.props.onTransitioning && this.props.onTransitioning();
            }


            if(
                (!isFromChapter || !isToChapter)
                && fromPage !== toPage
                && this.state.language === prevState.language
            ) {
                console.log("ENABLE TRANSITION !!!");
                TransitionActions.setKey(this.props.location.key);

                let self = this;

                this._transitionTimer = setTimeout(function() {

                    if(self.props.scrollbar && self._mounted) {
                        self.props.scrollbar.scrollTo(0, 0, 0);
                    }
                    else if(self._mounted) {
                        window.scrollTo(0, 0);
                    }
                    TransitionActions.set(false);
                }, 1000);
            }
            else {
                console.log("CANCEL TRANSITION !!!");
            }
        }
    }*/


    willUnmount() {
        if(this._transitionTimer) {
            clearTimeout(this._transitionTimer);
            this._transitionTimer = null;
        }

        if(this._unlisten) {
            this._unlisten();
        }

        window.removeEventListener("resize", this._resize);
    }

    setLanguage(language) {
        LanguageActions.set(language);
        //this.closeMenu();
    }

    toggleMenu() {
        this.props.onMenuChange && this.props.onMenuChange(!this.state.isOpen);
        this.setState({isOpen: !this.state.isOpen});

        this._animateHeaderCanvas(!this.state.isOpen);
    }

    closeMenu() {
        console.log(window.location.pathname);
        this.props.onMenuChange && this.props.onMenuChange(false);
        this.setState({isOpen: false});

        this._animateHeaderCanvas(false);
    }

    shouldAnimateTransition() {
        let shouldAnimate = false;

        let fromPage = this.state.transitionFrom;
        let toPage = this.state.transitionTo;

        if(this.state.transitioning) {
            if(
                (fromPage.indexOf("theme/") === -1 || toPage.indexOf("theme/") === -1)
                && fromPage !== toPage
                && fromPage.replace(getBasePath(), "").substring(3) !== toPage.replace(getBasePath(), "").substring(3)
                && toPage !== ""
            ) {
                shouldAnimate = true;
            }
        }

        return shouldAnimate;
    }

    onMouseEnter() {
        MouseActions.toggleHovering(true);
    }

    onMouseLeave() {
        MouseActions.toggleHovering(false);
    }

    // canvas background
    _setupHeaderCanvas() {
        this._headerCanvas.width = this._headerCanvas.parentNode.clientWidth;
        this._headerCanvas.height = this._headerCanvas.parentNode.clientHeight;
        this._ctx = this._headerCanvas.getContext("2d");
    }

    _drawHeaderCanvas() {
        let width = this._headerCanvas.width;
        let height = this._headerCanvas.height;

        this._ctx.clearRect(0, 0, width, height);

        /*** header background ***/
        //this._ctx.fillStyle = "#a7dde1";
        this._ctx.fillStyle = "#f3feff";

        this._ctx.beginPath();

        this._ctx.moveTo(0, 0);

        this._ctx.lineTo(0, this._headerBottomLeft);

        this._ctx.bezierCurveTo(width, this._headerBottomLeft, width / 3, this._headerBottomLeft, 0, this._headerBottomLeft);

        this._ctx.bezierCurveTo(width * 2 / 3, this._headerBottomRight, width, this._headerBottomRight, width, this._headerBottomRight);

        this._ctx.lineTo(width, 0);

        this._ctx.closePath();
        this._ctx.fill();


        /*** transition background ***/
        this._ctx.fillStyle = "#a7dde1";
        this._ctx.beginPath();

        this._ctx.moveTo(0, this._transitionTopLeft);
        this._ctx.bezierCurveTo(width, this._transitionTopLeft, width / 3, this._transitionTopLeft, 0, this._transitionTopLeft);

        this._ctx.bezierCurveTo(width * 2 / 3, this._transitionTopRight, width, this._transitionTopRight, width, this._transitionTopRight);

        this._ctx.lineTo(width, this._transitionBottomRight);

        this._ctx.bezierCurveTo(width * 2 / 3, this._transitionBottomRight, width, this._transitionBottomRight, width, this._transitionBottomRight);

        this._ctx.bezierCurveTo(width / 3, this._transitionBottomLeft, 0, this._transitionBottomLeft, 0, this._transitionBottomLeft);

        this._ctx.lineTo(0, this._transitionTopLeft);

        this._ctx.closePath();
        this._ctx.fill();
    }

    _resizeCanvas() {
        this._headerCanvas.width = this._headerCanvas.parentNode.clientWidth;
        this._headerCanvas.height = this._headerCanvas.parentNode.clientHeight;

        this._headerBottomRight = this.state.isOpen ? this._headerCanvas.height : 0;
        this._headerBottomLeft = this.state.isOpen ? this._headerCanvas.height : 0;

        this._transitionTopLeft = this._headerCanvas.height;
        this._transitionTopRight = this._headerCanvas.height;
        this._transitionBottomRight = this._headerCanvas.height;
        this._transitionBottomLeft = this._headerCanvas.height;


        this._drawHeaderCanvas();
    }

    _animateHeaderCanvas(isOpen) {
        this._headerBottomRight = isOpen ? 0 : this._headerCanvas.height;
        this._headerBottomLeft = isOpen ? 0 : this._headerCanvas.height;

        this._drawHeaderCanvas();

        let self = this;

        anime({
            targets: this,
            _headerBottomRight: isOpen ? this._headerCanvas.height : 0,
            duration: 1000,
            easing: 'easeInOutCubic',
        });

        anime({
            targets: this,
            _headerBottomLeft: isOpen ? this._headerCanvas.height : 0,
            duration: 1000,
            easing: isOpen ? 'easeOutQuart' : 'easeInQuart',
            update: function() {
                self._drawHeaderCanvas();
            }
        });
    }

    _animateTransitionCanvas() {
        this._transitionTopLeft = this._headerCanvas.height;
        this._transitionTopRight = this._headerCanvas.height;
        this._transitionBottomRight = this._headerCanvas.height;
        this._transitionBottomLeft = this._headerCanvas.height;

        let self = this;

        anime({
            targets: self,
            _transitionTopRight: 0,
            duration: 1000,
            easing: 'easeInOutCubic',
            complete: function() {
                anime({
                    targets: self,
                    _transitionBottomRight: 0,
                    duration: 1000,
                    delay: 250,
                    easing: 'easeInOutCubic',
                    complete: function() {
                        self._transitionBottomRight = 0;
                    }
                });
            }
        });

        anime({
            targets: this,
            _transitionTopLeft: 0,
            duration: 1000,
            easing: 'easeInQuart',
            update: function() {
                self._drawHeaderCanvas();
            },
            complete: function() {
                anime({
                    targets: self,
                    _transitionBottomLeft: 0,
                    duration: 1000,
                    delay: 250,
                    easing: 'easeOutQuart',
                    update: function() {
                        self._drawHeaderCanvas();
                    },
                    complete: function() {
                        self._transitionBottomLeft = 0;
                    }
                });
            }
        });
    }

    // get our DOM ref
    registerHeaderCanvas(el) {
        this._headerCanvas = el;
    }

    render() {
        let componentClass = "Header";

        let mainClass = componentClass;
        if(this.state.isOpen) {
            mainClass += " " + componentClass + "--opened";
        }

        let isTransitioning = this.shouldAnimateTransition();

        if(isTransitioning) {
            mainClass += " " + componentClass + "--transitioning";
        }


        let language = this.state.language;

        let basePath = getBasePath();

        let actualPage = "";
        if(this.props.location && this.props.location.pathname) {
            actualPage = this.props.location.pathname;
            actualPage = actualPage.replace(basePath, "");
            actualPage = actualPage.substring(3);
        }

        let data = menuData;

        let playButton = {
            fr: "Commencer la lecture",
            en: "Start watching",
        };

        let footerContent = {
            terms: {
                fr: "Mentions légales",
                en: "Terms and conditions",
            },
            sarah: {
                fr: "Photos et vidéos",
                en: "Pictures and videos",
            },
            guy: {
                fr: "Création",
                en: "Design",
            },
            martin: {
                fr: "Développement",
                en: "Development",
            },
        };

        let useLanguage = shouldUseLanguages();

        return (
            <header
                className={mainClass}
            >

                <canvas
                    ref={(el) => this.registerHeaderCanvas(el)}
                    className={componentClass + "-background"}
                />

                <div className={componentClass + "-inner"}>

                    <div className={componentClass + "-top"}>

                        { useLanguage &&
                        <div className={componentClass + "-top-language"}>
                            <div>
                                <NavLink
                                    to={basePath + "/fr" + actualPage}
                                    onClick={() => this.setLanguage("fr")}
                                    onMouseEnter={() => this.onMouseEnter()}
                                    onMouseLeave={() => this.onMouseLeave()}
                                >
                                    FR
                                </NavLink>
                                &nbsp;|&nbsp;
                                <NavLink
                                    to={basePath + "/en" + actualPage}
                                    onClick={() => this.setLanguage("en")}
                                    onMouseEnter={() => this.onMouseEnter()}
                                    onMouseLeave={() => this.onMouseLeave()}
                                >
                                    EN
                                </NavLink>
                            </div>
                        </div>
                        }

                        <div
                            className={componentClass + "-toggle-menu"}
                            onClick={() => this.toggleMenu()}
                            onMouseEnter={() => this.onMouseEnter()}
                            onMouseLeave={() => this.onMouseLeave()}
                        >
                            <FluidButton
                                deformation="7"
                                color="#a7dde1"
                                background="#f3feff"
                            >
                                <span
                                    className={componentClass + "-toggle-menu-button"}
                                >
                                    <span className={componentClass + "-toggle-menu-button-inner"}>
                                        Menu
                                    </span>
                                </span>
                            </FluidButton>
                        </div>
                    </div>

                    <nav className={componentClass + "-nav wrapper"}>
                        <ul className={componentClass + "-nav-menu"}>
                            { data && data.length > 0 && data.map((menuItem, i) =>
                                <li
                                    key={"menuItem-" + i}
                                    className={componentClass + "-nav-menu-item"}
                                >
                                    <NavLink
                                        exact
                                        to={basePath + "/" + language + "/" + menuItem.path}
                                        className={componentClass + "-nav-menu-item-link"}
                                        activeClassName={componentClass + "-nav-menu-item-link--active"}
                                        onClick={() => this.closeMenu()}
                                        onMouseEnter={() => this.onMouseEnter()}
                                        onMouseLeave={() => this.onMouseLeave()}
                                    >
                                        {menuItem.label[language]}
                                    </NavLink>

                                    { menuItem.submenu && menuItem.submenu.length > 0 &&
                                    <ul className={componentClass + "-nav-submenu"}>
                                        { menuItem.submenu.map((subMenuItem, j) =>
                                            <li
                                                key={"subMenuItem-" + j}
                                                className={componentClass + "-nav-submenu-item"}
                                            >
                                                <NavLink
                                                    //exact
                                                    //to={basePath + "/" + language + "/" + subMenuItem.slug}
                                                    to={basePath + "/" + language + "/" + menuItem.path + "#" + subMenuItem.path || ""}
                                                    className={componentClass + "-nav-submenu-item-link " + componentClass + "-nav-project-submenu-item-link"}
                                                    activeClassName={componentClass + "-nav-submenu-item-link--active"}
                                                    onClick={() => this.closeMenu()}
                                                    onMouseEnter={() => this.onMouseEnter()}
                                                    onMouseLeave={() => this.onMouseLeave()}
                                                >
                                                    <FluidButton
                                                        deformation="7"
                                                        color="#a7dde1"
                                                    >
                                                        <div className={componentClass + "-nav-submenu-item-link-inner"}>
                                                            <ArrowIcon />
                                                            <span>
                                                                {subMenuItem.label[language]}
                                                            </span>
                                                        </div>

                                                    </FluidButton>
                                                </NavLink>
                                            </li>
                                        )}

                                        { i === 0 &&
                                            <li className={componentClass + "-nav-submenu-item"}>
                                                <NavLink
                                                    to={basePath + "/" + language + "/" + themesData.list[0].slug + "/" + themesData.list[0].content[0].slug}
                                                    className={componentClass + "-nav-submenu-item-link " + componentClass + "-nav-project-submenu-item-link"}
                                                    activeClassName={componentClass + "-nav-submenu-item-link--active"}
                                                    onClick={() => this.closeMenu()}
                                                    onMouseEnter={() => this.onMouseEnter()}
                                                    onMouseLeave={() => this.onMouseLeave()}
                                                >
                                                    <FluidButton
                                                        color="#a7dde1"
                                                        deformation="7"
                                                    >
                                                        <div className={componentClass + "-nav-submenu-item-link-inner"}>
                                                            <ArrowIcon />
                                                            <span>{playButton[language]}</span>
                                                        </div>
                                                    </FluidButton>
                                                </NavLink>
                                            </li>
                                        }
                                    </ul>
                                    }
                                </li>
                            )}
                        </ul>
                    </nav>


                    <div className={componentClass + "-footer"}>
                        <div className={componentClass + "-footer-inner"}>
                            <NavLink
                                to={basePath + "/" + language + "/mentions-legales"}
                                onClick={() => this.closeMenu()}
                                onMouseEnter={() => this.onMouseEnter()}
                                onMouseLeave={() => this.onMouseLeave()}
                            >
                                {footerContent.terms[language]}
                            </NavLink>
                            &nbsp;- {footerContent.sarah[language]} <a href="http://www.sarahdelben.com" title="Sarah del Ben - Photos et vidéos" target="_blank" rel="noopener" onMouseEnter={() => this.onMouseEnter()} onMouseLeave={() => this.onMouseLeave()}>Sarah Del Ben</a> - {footerContent.guy[language]} <a href="https://kanu.fr/" title="Kanu - design" target="_blank" rel="noopener" onMouseEnter={() => this.onMouseEnter()} onMouseLeave={() => this.onMouseLeave()}>kanu.fr</a> - {footerContent.martin[language]} <a href="https://www.martin-laxenaire.fr/" title="Martin Laxenaire - développement créatif" target="_blank" rel="noopener" onMouseEnter={() => this.onMouseEnter()} onMouseLeave={() => this.onMouseLeave()}>martin-laxenaire.fr</a>
                        </div>
                    </div>

                </div>

            </header>
        );
    }
}

export default withRouter(Header);
