/**
 *
 * @author Fractal-IT
 */
import React from 'react';
import ScrollComponentBase from './base/ScrollComponentBase';

import {getBasePath} from '../utils/config';

import './TeamThumb.scss';

class TeamThumb extends ScrollComponentBase {

    static baseClassName = "TeamThumb";

    _mounted = false;

    didMount() {
        this._mounted = true;
    }

    willUnmount() {
        this._mounted = false;
    }

    /*** Custom methods ***/

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;

        let member = this.props.member;
        let language = this.state.language;

        let basePath = getBasePath();

        return (
            <article
                className={this.getMainCSSClass()}
                ref={(el) => this.registerElement(el)}
            >
                { member.thumb &&
                    <div className={componentClass + "-thumb"}>
                        <figure className={componentClass + "-thumb-figure"}>
                            <img src={basePath + member.thumb} alt={member.firstname + " " + member.lastname} width="400" height="400" />
                        </figure>
                    </div>
                }
                <div className={componentClass + "-infos"}>
                    <h2 className={componentClass + "-name"}>
                        <span className={componentClass + "-name-first"}>{member.firstname}</span>&nbsp;
                        <span className={componentClass + "-name-last"}>{member.lastname}</span>
                    </h2>

                    { member.desc &&
                    <div
                        dangerouslySetInnerHTML={{__html: member.desc[language]}}
                        className={componentClass + "-desc"}
                    />
                    }
                </div>
            </article>
        );
    }
}

export default TeamThumb;
