/**
 *
 * @author Fractal-IT
 */
import React from 'react';
import {Helmet} from "react-helmet";

import ScrollComponentBase from './base/ScrollComponentBase';
import AnimatedTitle from './AnimatedTitle';
import TeamThumb from './TeamThumb';

import {getBasePath, getSiteOrigin, stripTags} from "../utils/config";
import {seoData} from "../data/seo";

import './TeamList.scss';

class TeamList extends ScrollComponentBase {

    static baseClassName = "TeamList";

    _mounted = false;

    didMount() {
        this._mounted = true;
    }

    willUnmount() {
        this._mounted = false;
    }

    /*** Custom methods ***/

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;

        let {language, visible} = this.state;
        let {data} = this.props;

        let siteOrigin = getSiteOrigin();
        let basePath = getBasePath();

        return (
            <div
                className={this.getMainCSSClass()}
                ref={(el) => this.registerElement(el)}
            >

                {data && data.intro && data.intro.title &&
                <Helmet>
                    <title>{data.intro.title[language] + " | " + seoData.title[language]}</title>
                    {seoData.desc &&
                    <meta name="description" content={seoData.desc[language]}/>
                    }
                    <link rel="canonical" href={siteOrigin + basePath + "/" + language + "/le-projet"} />

                    <meta property="og:title" content={data.intro.title[language] + " | " + seoData.title[language]} />
                    {seoData.desc &&
                    <meta property="og:description" content={stripTags(seoData.desc[language])}/>
                    }
                    <meta property="og:url" content={siteOrigin + basePath + "/" + language + "/le-projet"} />

                    <meta property="og:image" content={siteOrigin + basePath + "/images/sensei-sentinels-of-the-sea-ice-socials.jpg"} />

                    <meta name="twitter:title" content={data.intro.title[language] + " | " + seoData.title[language]} />
                    {seoData.desc &&
                    <meta name="twitter:description" content={stripTags(seoData.desc[language])} />
                    }
                    <meta name="twitter:image" content={siteOrigin + basePath + "/images/sensei-sentinels-of-the-sea-ice-socials.jpg"} />
                </Helmet>
                }

                <div className="wrapper">

                    <section className={componentClass + "-header"}>

                        { data && data.intro && data.intro.title &&
                        <h1 className={componentClass + "-title  ProjectPage-title"}>
                            <AnimatedTitle
                                animate={visible} // TODO
                            >
                                {data.intro.title[language]}
                            </AnimatedTitle>
                        </h1>
                        }

                    </section>

                    { data.team && data.team.content && data.team.content.length > 0 &&
                    <section className={componentClass + "-team"} id="l-equipe">

                        { data && data.team.title &&
                        <h2 className={componentClass + "-subtitle ProjectPage-subtitle"}>
                            <AnimatedTitle
                                animate={visible}
                            >
                                {data.team.title[language]}
                            </AnimatedTitle>
                        </h2>
                        }

                        <div className={componentClass + "-team-list"}>
                            { data.team.content.map((member, i) =>
                                <div key={"team-thumb-" + i} className={componentClass + "-team-list-item"}>
                                    <TeamThumb
                                        member={member}
                                    />
                                </div>
                            )}
                        </div>
                    </section>
                    }
                </div>
            </div>
        );
    }
}

export default TeamList;