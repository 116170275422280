import Reflux        from 'reflux';
import {LanguageActions} from "./LanguageStoreActions";


export class LanguageStore extends Reflux.Store {

    constructor(initialState = {}) {
        super();

        this.state = {
            language: "fr",
        };

        this.listenables = LanguageActions;
    }

    set(language) {
        console.debug("Set language", language);
        this.setState({language});
    }


}