export const termsData = {
    intro: {
        title: {
            fr: "Mentions légales",
            en: "Terms and conditions",
        },
    },

    sections: [
        {
            title: {
                fr: "Informations légales",
                en: "",
            },
            content: {
                fr: "<p>En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux utilisateurs du site <a href=\"https://www.projetsensei.com/\" title=\"Projet SENSEI\">www.projetsensei.com</a> l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p><p>Propriétaire : Projet SENSEI – 62 rue d’Yvours, 69540 Irigny.</p><p>Créateurs : Photos et vidéos <a href=\"http://www.sarahdelben.com\" title=\"Sarah del Ben - Photos et vidéos\" target=\"_blank\" rel=\"noopener\">Sarah Del Ben</a> - Création <a href=\"https://kanu.fr/\" title=\"Kanu - design\" target=\"_blank\" rel=\"noopener\">kanu.fr</a> - Développement <a href=\"https://www.martin-laxenaire.fr/\" title=\"Martin Laxenaire - développement créatif\" target=\"_blank\" rel=\"noopener\">martin-laxenaire.fr</a></p><p>Responsable publication : Projet SENSEI – <a href=\"mailot:projetsensei@gmail.com\" title=\"Contacter le projet SENSEI\">projetsensei@gmail.com</a></p><p>Hébergeur : OVH – 2 rue Kellermann 59053 ROUBAIX – <a href=\"https://www.ovh.com/\" title=\"OVH\" target=\"_blank\">www.ovh.com</a></p>",
                en: "",
            }
        },

        {
            title: {
                fr: "Renseignements personnels recueillis",
                en: "",
            },
            content: {
                fr: "<p>Lorsque vous naviguez sur notre site Internet, nous recevons également automatiquement l’adresse de protocole Internet (adresse IP) de votre ordinateur, qui nous permet d’obtenir plus de détails au sujet du navigateur et du système d’exploitation que vous utilisez.</p><p>Lorsque vous nous fournissez vos renseignements personnels, nous présumons que vous consentez à ce que nous recueillions vos renseignements et à ce que nous les utilisions à cette fin uniquement.</p><p>Si nous vous demandons de nous fournir vos renseignements personnels pour une autre raison, nous vous demanderons directement votre consentement explicite, ou nous vous donnerons la possibilité de refuser.</p><p>Si après nous avoir donné votre consentement, vous changez d’avis et ne consentez plus à ce que nous puissions vous contacter ou recueillir vos renseignements, vous pouvez nous en aviser en nous contactant à <a href=\"mailot:projetsensei@gmail.com\" title=\"Contacter le projet SENSEI\">projetsensei@gmail.com</a>.</p>",
                en: "",
            }
        },

        {
            title: {
                fr: "Divulgation",
                en: "",
            },
            content: {
                fr: "<p>Nous nous engageons à protéger vos données à caractère personnel collectées, à ne pas les transmettre à des tiers sans vous en avoir informé et à respecter les finalités pour lesquelles ces données ont été collectées.<br />Dans le cas où l’intégrité, la confidentialité ou la sécurité de vos données à caractère personnel est compromise, nous nous engageons à vous en informer par tout moyen.</p>",
                en: "",
            }
        },

        {
            title: {
                fr: "Hébergement",
                en: "",
            },
            content: {
                fr: "<p>Tel que mentionné plus haut, le site <a href=\"https://www.projetsensei.com/\" title=\"Projet SENSEI\">www.projetsensei.com</a> est hébergé par OVH, dont le siège est situé à l’adresse suivante : 2 rue Kellermann 59053 ROUBAIX – France.</p><p>Les données collectées et traitées par le site sont exclusivement hébergées et traitées en France.</p>",
                en: "",
            }
        },

        {
            title: {
                fr: "Services fournis par des tiers",
                en: "",
            },
            content: {
                fr: "<p>De manière générale, les fournisseurs tiers que nous utilisons vont uniquement recueillir, utiliser et divulguer vos renseignements dans la mesure du nécessaire pour pouvoir réaliser les services qu’ils nous fournissent.</p>",
                en: "",
            }
        },

        {
            title: {
                fr: "Liens et cookies",
                en: "",
            },
            content: {
                fr: "<p>Le site <a href=\"https://www.projetsensei.com/\" title=\"Projet SENSEI\">www.projetsensei.com</a> contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation du Projet SENSEI. Cependant, Le Projet SENSEI n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait. Aussi, nous vous recommandons de consulter les politiques de confidentialité desdits sites.</p><p>La navigation sur le site <a href=\"https://www.projetsensei.com/\" title=\"Projet SENSEI\">www.projetsensei.com</a> est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.</p><p>Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l’installation des cookies :</p><p>Sous Internet Explorer : onglet outil / options internet. Cliquez sur Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok.</p><p>Sous Google Chrome : onglet outil / paramètres / paramètres avancés. Dans l’onglet paramètres du contenu cliquez sur Cookies et décochez « Autoriser les sites à enregistrer et à lire les données des cookies »</p>",
                en: "",
            }
        },

        {
            title: {
                fr: "Sécurité",
                en: "",
            },
            content: {
                fr: "<p>Pour protéger vos données personnelles, nous prenons des précautions raisonnables et suivons les meilleures pratiques de l’industrie pour nous assurer qu’elles ne soient pas perdues, détournées, consultées, divulguées, modifiées ou détruites de manière inappropriée.</p>",
                en: "",
            }
        },

        {
            title: {
                fr: "Modifications apportées à la présente politique de confidentialité",
                en: "",
            },
            content: {
                fr: "<p>Nous nous réservons le droit de modifier la présente politique de confidentialité à tout moment, donc veuillez s’il vous plait la consulter fréquemment. Les changements et les clarifications prendront effet immédiatement après leur publication sur le site web. Si nous apportons des changements au contenu de cette politique, nous vous aviserons ici qu’elle a été mise à jour, pour que vous sachiez quels renseignements nous recueillons, la manière dont nous les utilisons, et dans quelles circonstances nous les divulguons, s’il y a lieu de le faire.</p>",
                en: "",
            }
        },

        {
            title: {
                fr: "Questions et coordonnées",
                en: "",
            },
            content: {
                fr: "<p>Si vous souhaitez : accéder à, corriger, modifier ou supprimer toute information personnelle que nous avons à votre sujet, déposer une plainte, ou si vous souhaitez simplement avoir plus d’informations, contactez notre agent responsable des normes de confidentialité à <a href=\"mailot:projetsensei@gmail.com\" title=\"Contacter le projet SENSEI\">projetsensei@gmail.com</a>.</p>",
                en: "",
            }
        },
    ],
};