import React from 'react';

import ScrollComponentBase from './base/ScrollComponentBase';
import {MouseActions} from "../globals/MouseStoreActions";

import './PartnersLists.scss';
import AnimatedTitle from "./AnimatedTitle";
import {getBasePath} from "../utils/config";

class PartnersLists extends ScrollComponentBase {

    static baseClassName = "PartnersLists";

    /*** React component lifecycle ***/

    didMount() {
        //console.log("Home intro did mount", this._scrollValue);
    }

    /*** Listeners ***/

    onScroll() {
        //console.log(this._scrollValue);
    }

    /*** Custom methods ***/

    onMouseEnter() {
        MouseActions.toggleHovering(true);
    }

    onMouseLeave() {
        MouseActions.toggleHovering(false);
    }

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;

        let {language, visible} = this.state;
        let {data} = this.props;

        let basePath = getBasePath();

        return (
            <section
                className={this.getMainCSSClass()}
                ref={(el) => this.registerElement(el)}
                id="les-partenaires"
            >

                <h2 className={componentClass + "-title ProjectPage-subtitle"}>
                    <AnimatedTitle
                        animate={visible}
                    >
                        {data.title[language]}
                    </AnimatedTitle>
                </h2>

                { data && data.highlighted && data.highlighted.length > 0 &&
                    <div className={componentClass + "-list-wrapper " + componentClass + "-list-wrapper--highlighted"}>
                        <div className="wrapper">
                            <ul className={componentClass + "-list " + componentClass + "-list--highlighted"}>
                                { data.highlighted.map((partner, i) =>
                                    <li
                                        key={"highlighted-partner-" + i}
                                        className={componentClass + "-list-item"}
                                    >
                                        { partner.link ?
                                            <a
                                                href={partner.link}
                                                target="_blank"
                                                title={partner.label}
                                                rel="noopener"
                                                className={componentClass + "-list-item-inner"}
                                                onMouseEnter={() => this.onMouseEnter()}
                                                onMouseLeave={() => this.onMouseLeave()}
                                            >
                                                { partner.thumb ?
                                                    <figure>
                                                        <img src={basePath + partner.thumb} alt={partner.label} />
                                                    </figure>
                                                :
                                                    <span>{partner.label}</span>
                                                }
                                            </a>
                                        :
                                            <div className={componentClass + "-list-item-inner"}>
                                                { partner.thumb ?
                                                    <figure>
                                                        <img src={basePath + partner.thumb} alt={partner.label} />
                                                    </figure>
                                                :
                                                    <span>{partner.label}</span>
                                                }
                                            </div>
                                        }
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                }

                { data && data.regular && data.regular.length > 0 &&
                    <div className={componentClass + "-list-wrapper"}>
                        <div className="wrapper">
                            <ul className={componentClass + "-list"}>
                                { data.regular.map((partner, i) =>
                                    <li
                                        key={"partner-" + i}
                                        className={componentClass + "-list-item"}
                                    >
                                        { partner.link ?
                                            <a
                                                href={partner.link}
                                                target="_blank"
                                                title={partner.label}
                                                rel="noopener"
                                                className={componentClass + "-list-item-inner"}
                                                onMouseEnter={() => this.onMouseEnter()}
                                                onMouseLeave={() => this.onMouseLeave()}
                                            >
                                                { partner.thumb ?
                                                    <figure>
                                                        <img src={partner.thumb} alt={partner.label} />
                                                    </figure>
                                                    :
                                                    <span>{partner.label}</span>
                                                }
                                            </a>
                                            :
                                            <div className={componentClass + "-list-item-inner"}>
                                                { partner.thumb ?
                                                    <figure>
                                                        <img src={partner.thumb} alt={partner.label} />
                                                    </figure>
                                                    :
                                                    <span>{partner.label}</span>
                                                }
                                            </div>
                                        }
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                }

            </section>
        );
    }
}

export default PartnersLists;