import React from 'react';
import {Helmet} from "react-helmet";

import {getBasePath, getSiteOrigin, stripTags, getStripedMetaDesc} from '../utils/config';

import ComponentBase from '../components/base/ComponentBase';
import LinkBase from '../components/base/LinkBase';

import ChapterShortNavigation from '../components/ChapterShortNavigation';
import VideoPlayer from '../components/VideoPlayerPlyr';
import ChapterNavigation from '../components/ChapterNavigation';

import FluidButton from '../components/FluidButton';

/*** DATA ***/
import {themesData} from '../data/themes.js';
import {seoData} from '../data/seo.js';

/*** STYLES ***/
import './ChapterPage.scss';

class ChapterPage extends ComponentBase {

    _mounted = true;

    _linkDelay = 1000;

    didMount() {
        this._mounted = true;

        // get current theme from chapter slug and themes data
        let actualChapter = this.getActualChapter();

        if(actualChapter.chapter) {
            console.log(">> chapter page did mount", actualChapter.chapter);
        }

        this._delayTimer = null;
    }

    didUpdate(prevProps, prevState) {
        // pause player when leaving page to improve transition perf
        if(!prevProps.transitioning && this.props.transitioning && this._videoPlayer) {
            if(this._videoPlayer.player) {
                this._videoPlayer.player.pause();
            }
        }
    }

    willUnmount() {
        this._mounted = false;
        console.log("chapter page will UNmount");

        if(this._delayTimer) {
            clearTimeout(this._delayTimer);
        }
    }

    getActualChapter() {
        // get chapter slug from url
        let currentChapter = this.props.chapter;

        let actualChapter = {};

        // get current theme from chapter slug and themes data
        for(let i = 0; i < themesData.list.length; i++) {
            for(let j = 0; j < themesData.list[i].content.length; j++) {
                if(themesData.list[i].content[j].slug === currentChapter) {
                    actualChapter.chapter = themesData.list[i].content[j];
                    actualChapter.themeIndex = i;
                    actualChapter.chapterIndex = j;
                }
            }
        }

        return actualChapter;
    }


    onVideoEnd() {
        let {language} = this.state;

        if(this._mounted) {
            this.setState({
                duration: 0,
                currentTime: 0,
            });
        }

        // get current theme from chapter slug and themes data
        let actualChapter = this.getActualChapter();

        let themeIndex = actualChapter.themeIndex;
        let chapterIndex = actualChapter.chapterIndex;

        let nextThemeIndex, nextChapterIndex;

        // what is next video ?
        let currentTheme = themesData.list[themeIndex];

        if(chapterIndex < currentTheme.content.length - 1) {
            nextThemeIndex = themeIndex;
            nextChapterIndex = chapterIndex + 1;
        }
        else {
            if(themeIndex < themesData.list.length - 1) {
                nextThemeIndex = themeIndex + 1;
                nextChapterIndex = 0;
            }
        }

        if(nextThemeIndex != null && nextChapterIndex != null) {
            let nextChapter = themesData.list[nextThemeIndex].content[nextChapterIndex];
            let themeSlug = themesData.list[nextThemeIndex].slug;
            console.log(">> VIDEO ENDED, next chapter", nextChapter);

            let basePath = getBasePath();

            // change url
            if(this._mounted) {
                this.props.history.push(basePath + "/" + language + "/" + themeSlug + "/" + nextChapter.slug);
            }
        }
    }

    onVideoTimeUpdate(currentTime, duration) {
        if(this._mounted) {
            this.setState({
                currentTime: currentTime,
                duration: duration,
            });
        }
    }

    onLinkClicked() {
        console.log(">>> navigate from video to video on click !!!", this._page.classList);

        let pageClassList = this._page.classList;
        pageClassList.add("ChapterPage--transition");

        if(this._videoPlayer.player) {
            this._videoPlayer.player.pause();
        }

        this._delayTimer = setTimeout(function() {
            pageClassList.remove("ChapterPage--transition");
        }, this._linkDelay);
    }

    registerPage(el) {
        this._page = el;
    }

    registerPlayer(el) {
        this._videoPlayer = el;
    }


    render() {
        let pageClass = "ChapterPage";

        let {language} = this.state;

        // get current theme from chapter slug and themes data
        let actualChapter = this.getActualChapter();

        let chapter = actualChapter.chapter;
        if(!chapter) return <div />;

        let themeIndex = actualChapter.themeIndex;
        let chapterIndex = actualChapter.chapterIndex;

        let siteOrigin = getSiteOrigin();
        let basePath = getBasePath();

        // what is next video ?
        let currentTheme = themesData.list[themeIndex];

        let pageTitle = chapter.title[language];
        if(currentTheme && currentTheme.title) {
            pageTitle += " | " + currentTheme.title[language];
        }
        pageTitle += " | " + seoData.title[language];

        return (
            <div
                className={pageClass}
                ref={(el) => this.registerPage(el)}
            >

                <Helmet>
                    <title>{pageTitle}</title>
                    { chapter && chapter.desc &&
                    <meta name="description" content={getStripedMetaDesc(chapter.desc[language])} />
                    }
                    <link rel="canonical" href={siteOrigin + basePath + "/" + language + "/" + currentTheme.slug + "/" + chapter.slug} />

                    <meta property="og:title" content={pageTitle} />
                    {chapter && chapter.desc &&
                    <meta property="og:description" content={stripTags(chapter.desc[language])}/>
                    }
                    <meta property="og:url" content={siteOrigin + basePath + "/" + language + "/" + currentTheme.slug + "/" + chapter.slug} />
                    {chapter && chapter.thumb &&
                    <meta property="og:image" content={siteOrigin + basePath + chapter.thumb + "-medium.jpg"} />
                    }

                    <meta name="twitter:title" content={pageTitle} />
                    {chapter && chapter.desc &&
                    <meta name="twitter:description" content={stripTags(chapter.desc[language])} />
                    }
                    {chapter && chapter.thumb &&
                    <meta name="twitter:image" content={siteOrigin + basePath + chapter.thumb + "-medium.jpg"} />
                    }
                </Helmet>

                <div className={pageClass + "-inner wrapper"}>

                    <div className={pageClass + "-header"}>
                        { chapter.title &&
                        <h1 className={pageClass + "-title"}>
                            <span className={pageClass + "-title-index"}>
                                Ep. {chapter.index}
                            </span>
                            <span>
                                {chapter.title[language]}
                            </span>
                        </h1>
                        }

                        { currentTheme.title &&
                        <h2 className={pageClass + "-theme"}>

                            <LinkBase
                                to={basePath + "/" + language + "/#" + currentTheme.slug || ""}
                                className={pageClass + "-theme-link"}
                            >
                                <FluidButton
                                    deformation={12}
                                    color="#a7dde1"
                                >

                                    <span className={pageClass + "-theme-link-inner"}>{currentTheme.title[language]}</span>

                                </FluidButton>
                            </LinkBase>
                        </h2>
                        }

                        {chapter.desc &&
                        <div
                            className={pageClass + "-desc"}
                            dangerouslySetInnerHTML={{__html: chapter.desc[language]}}
                        />
                        }
                    </div>
                </div>


                <div className={pageClass + "-player"}>
                    <ChapterShortNavigation
                        chapter={chapter}

                        history={this.props.history}
                        delay={this._linkDelay}
                        onLinkClicked={() => this.onLinkClicked()}
                    />

                    <div className={pageClass + "-player-video wrapper"}>
                        <VideoPlayer
                            ref={(el) => this.registerPlayer(el)}
                            video={chapter.video}
                            onEnd={() => this.onVideoEnd()}
                            onTimeUpdate={(currentTime, duration) => this.onVideoTimeUpdate(currentTime, duration)}
                        />
                    </div>
                </div>

                <div className={pageClass + "-chapter-navigation wrapper"}>

                    <ChapterNavigation
                        themeIndex={themeIndex}
                        chapterIndex={chapterIndex}

                        history={this.props.history}
                        delay={this._linkDelay}
                        onLinkClicked={() => this.onLinkClicked()}

                        currentTime={this.state.currentTime}
                        duration={this.state.duration}
                    />
                </div>

            </div>
        );
    }
}

export default ChapterPage;