import React from 'react';

import LinkBase from './base/LinkBase';

import ComponentBase from './base/ComponentBase';

/*** DATA ***/
import {themesData} from '../data/themes.js';

import {getBasePath} from '../utils/config';

import './ChapterNavigation.scss';

class ChapterNavigation extends ComponentBase {

    static baseClassName = "ChapterNavigation";

    /*** React component lifecycle ***/

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.chapterIndex !== this.props.chapterIndex) {
            console.log("switching vids should not update", nextProps.chapterIndex, this.props.chapterIndex);
        }
        return nextProps.chapterIndex === this.props.chapterIndex;
    }

    /*** Custom methods ***/

    onLinkClicked() {
        this.props.onLinkClicked && this.props.onLinkClicked();
    }

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;

        let basePath = getBasePath();

        let {themeIndex, chapterIndex, history, delay} = this.props;
        let {language} = this.state;

        let currentTheme/*, previousTheme, nextTheme*/;
        currentTheme = themesData.list[themeIndex];

        /*if(themeIndex > 0) {
            previousTheme = themesData.list[themeIndex - 1];
        }

        if(themeIndex < themesData.list.length - 1) {
            nextTheme = themesData.list[themeIndex + 1];
        }*/

        let currentTime = this.props.currentTime || 0;
        let duration = this.props.duration || 1;
        let progress = (currentTime / duration) * 100;
        progress = progress.toFixed(2);

        return (
            <nav
                className={componentClass}
                ref={(el) => this.registerElement(el)}
            >
                {/* previousTheme && previousTheme.title &&
                <div className={componentClass + "-previous"}>
                    {previousTheme.title[language]}
                </div>
                */}

                {/* nextTheme && nextTheme.title &&
                <div className={componentClass + "-next"}>
                    {nextTheme.title[language]}
                </div>
                */}

                { currentTheme && currentTheme.content.length > 0 &&
                <div className={componentClass + "-list"}>
                    { currentTheme.content.map((chapter, i) =>
                        <span
                            key={"chapter-" + i}
                            className={componentClass + "-list-item"}
                        >
                            {
                                chapter.index &&
                                <LinkBase
                                    to={basePath + "/" + language + "/" + currentTheme.slug + "/" + chapter.slug}
                                    className={chapterIndex === i ? componentClass + "-list-item-chapter active" : componentClass + "-list-item-chapter"}

                                    history={history}
                                    delay={delay}
                                    onDelayedClick={() => this.onLinkClicked()}
                                >
                                    <span className={componentClass + "-list-item-chapter-index"}>
                                        Ep. {chapter.index}
                                    </span>

                                    {i < chapterIndex &&
                                    <span
                                        className={componentClass + "-list-item-chapter-progress " + componentClass + "-list-item-chapter-progress--played"}
                                        style={{width: "100%"}}>&nbsp;100%</span>
                                    }

                                    {i === chapterIndex &&
                                        <span className={componentClass + "-list-item-chapter-progress"} style={{width: "calc(" + progress + "% + 12px)"}}>&nbsp;{progress}%</span>
                                    }
                                </LinkBase>
                            }
                        </span>
                    )}
                </div>
                }
            </nav>
        );
    }
}

export default ChapterNavigation;
