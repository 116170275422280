import PropTypes from 'prop-types';

import ComponentBase from './ComponentBase';
import {LanguageStore} from "../../globals/LanguageStore";
import {TransitionStore} from "../../globals/TransitionStore";

// TODO use intersection observer ?

class ScrollComponentBase extends ComponentBase {

    static contextTypes = {
        getScrollbar: PropTypes.func
    };

    _mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
        this.stores = [LanguageStore, TransitionStore];

        this._visibilityTimer = null;
    }

    /*** React component lifecycle ***/

    /*componentWillUpdate(nextProps, nextState) {
        if(!nextState.transitioning) {
            this._handleScroll(0);
        }
    }*/

    didUpdate(prevProps, prevState) {
        if(!this.state.transitioning) {
            this._handleScroll(0);
        }
    }

    componentDidMount() {
        this._mounted = true;

        // init all values related to scroll and visibility
        this._handleResize();
        //this._handleScroll(1250);

        this.context.getScrollbar((scrollbar) => {
            this._scrollbar = scrollbar;
            this._scrollbar.addListener(this._handleVirtualScroll.bind(this, 100));
        });

        // init listeners
        //this._scrollListener = this._handleScroll.bind(this, 100);
        //window.addEventListener("scroll", this._scrollListener, {passive: true});

        this._resizeListener = this._handleResize.bind(this);
        window.addEventListener("resize", this._resizeListener);

        this.didMount && this.didMount();
    }

    componentWillUnmount() {
        this._mounted = false;

        if(this._scrollListener) {
            window.removeEventListener("scroll", this._scrollListener, {passive: true});
        }

        if(this._scrollbar) {
            this._scrollbar.removeListener(this._handleVirtualScroll);
        }

        if(this._resizeListener) {
            window.removeEventListener("resize", this._resizeListener);
        }

        if(this._visibilityTimer) {
            clearTimeout(this._visibilityTimer);
            this._visibilityTimer = null;
        }

        this.willUnmount && this.willUnmount();

        super.componentWillUnmount();
    }

    /*** Listeners ***/

    _handleVirtualScroll(delay) {
        console.log("virtual scroll", delay, this.state.transitioning);
        if(!this.state.animating) {
            this._handleScroll(delay);
        }
    }

    _handleScroll(delay) {
        if(this._scrollbar) {
            this._scrollValue = this._scrollbar.offset.y;
        }
        else {
            this._scrollValue = window.pageYOffset;
        }

        let isVisible = this._visibilityCheck();
        if(!this.state.visible && isVisible) {
            var self = this;
            this._visibilityTimer = setTimeout(function() {
                if(self._mounted) {
                    self.setState({visible: isVisible});
                }
            }, delay);
        }

        this.onScroll && this.onScroll();
    }

    _handleResize() {
        this._window = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }

    /*** Custom methods ***/

    _visibilityCheck() {
        if(this._el) {
            let boundingRect = this._el.getBoundingClientRect();

            return boundingRect.top < this._window.height;
        }
        else {
            return false;
        }
    }

    getMainCSSClass() {
        let baseClass = this.constructor.baseClassName || "ScrollComponent";
        let mainClass = baseClass;

        if(this.state.visible) {
            mainClass += " " + baseClass + "--visible";
        }

        return mainClass;
    }

}

export default ScrollComponentBase;
