import React from 'react';
import anime from 'animejs';

import './AnimatedTitle.scss';

class AnimatedTitle extends React.Component {
    static baseClassName = "AnimatedTitle";

    /*** React component lifecycle ***/

    componentWillUpdate(nextProps, nextState) {
        let componentClass = this.constructor.baseClassName;
        let letters = '.' + componentClass + '-letter';

        if(!this.props.animate && nextProps.animate && this._el) {
            let lettersElement = this._el.querySelectorAll(letters);
            let delay = this.props.delay || 0;
            let staggering = this.props.staggering || 50;

            anime({
                targets: lettersElement,
                opacity: [0, 1],
                translateY: [20, 0],
                easing: "easeOutExpo",
                duration: 1000,
                delay: function(el, i) {
                    return delay + i * staggering;
                },
            });
        }
    }

    /*** Custom methods ***/

    wrapTitle(title) {
        let componentClass = this.constructor.baseClassName;
        let returnFragment = title.split(' ').map((word, index) => {
            return <React.Fragment key={index}>
                <div className={componentClass + "-word"}>
                    {/*word*/}
                    {word.split('').map((letter, letterIndex) => {
                        return <React.Fragment key={letterIndex}>
                            <span className={componentClass + "-letter"}>{letter}</span>
                        </React.Fragment>
                    })}
                </div>
            </React.Fragment>
        });

        return returnFragment
    }

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;

        if(this.props.animate) {
            componentClass += " " + componentClass + "--animated";
        }

        let wrappedTitle = this.wrapTitle(this.props.children);

        return (
            <div
                className={componentClass}
                ref={(el) => this.registerElement(el)}
            >
                {wrappedTitle}
            </div>
        );
    }
}

export default AnimatedTitle;
