import React from 'react';
import Reflux from 'reflux';
import PropTypes from 'prop-types';

/*** COMPONENTS ***/
import HomeIntro from '../components/HomeIntro';
import HomeDesc from '../components/HomeDesc';
import ThemeSection from '../components/ThemeSection';

/*** DATA ***/
import {homeData} from '../data/home.js';
import {themesData} from '../data/themes.js';

/*** STYLES ***/
import './HomePage.scss';

class HomePage extends Reflux.Component {

    static contextTypes = {
        getScrollbar: PropTypes.func
    };

    _mounted = false;

    constructor(props) {
        super(props);

        this.state = {
            scrollbar: null,
        };

        //this.store = TransitionStore;
    }

    /*** React component lifecycle ***/

    componentDidMount() {
        this._mounted = true;
        this.context.getScrollbar((scrollbar) => {
            if(this._mounted) {
                this.setState({scrollbar: scrollbar});
            }
        });
    }

    componentWillUpdate(nextProps, nextState) {
        if(!nextProps.transitioning && nextState.scrollbar && this._mounted) {
            this.scrollToSection(nextState.scrollbar);
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    scrollToSection(scrollbar) {
        let hash = window.location.hash;
        if(hash && this._el) {
            let targetDiv = this._el.querySelector(hash);

            let self = this;

            if(targetDiv) {
                setTimeout(function() {
                    if(scrollbar && self._mounted) {
                        scrollbar.update();
                        // reset scroll
                        scrollbar.setPosition(0, 0);
                        // apply new scroll
                        scrollbar.setPosition(0, targetDiv.getBoundingClientRect().top - 40);
                    }
                    else if(self._mounted) {
                        window.scrollTo(0, targetDiv.getBoundingClientRect().top - 40);
                    }
                }, 0);
            }
        }
    }

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let pageClass = "HomePage";

        return (
            <div
                className={pageClass}
                ref={(el) => this.registerElement(el)}
            >

                { homeData && homeData.intro &&
                    <HomeIntro
                        data={homeData.intro}
                    />
                }

                { homeData && homeData.desc &&
                <HomeDesc
                    data={homeData.desc}
                    themesData={themesData}
                />
                }

                {themesData && themesData.list && themesData.list.length > 0 &&
                    <div className={pageClass + "-themes"} id="videos">

                        {themesData.list.map((item, i) =>
                            <ThemeSection
                                key={"section-" + i}
                                data={item}
                            />
                        )}

                    </div>
                }

            </div>
        );
    }
}

export default HomePage;
