import React from 'react';

export default class ArrowIcon extends React.Component {

    render() {
        let color = this.props.color || "currentColor";

        return (
            <svg x="0px" y="0px" width="31.5px" height="22.1px" viewBox="0 0 31.5 22.1">
                <path fill={color} d="M21.2,0.3c-0.4-0.4-1.1-0.4-1.6,0c-0.4,0.4-0.4,1.1,0,1.6l8,8H1.1C0.5,10,0,10.4,0,11.1s0.5,1.1,1.1,1.1h26.6
	l-8,8c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0l10-10c0.4-0.4,0.4-1.1,0-1.6L21.2,0.3z"/>
            </svg>
        );
    }
}
