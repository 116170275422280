export const themesData = {
    list: [
        {
            title: {
                fr: "Le projet Sensei",
                en: "Sensei Project",
            },
            slug: "le-projet-sensei",
            content: [
                {
                    slug: "un-contexte-bien-particulier",
                    index: 1,
                    title: {
                        fr: "Un contexte bien particulier",
                        en: "Un contexte bien particulier",
                    },
                    desc: {
                        fr: "<p>Christophe Barbraud nous explique le contexte particulier dans lequel le projet SENSEI est né.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/un-contexte-bien-particulier",
                    //video: "https://www.youtube.com/watch?v=32ZbKDYyRwY",
                    video: "https://www.youtube.com/watch?v=iSR76A9N01c",
                },
                {
                    slug: "le-projet-en-quelques-mots",
                    index: 2,
                    title: {
                        fr: "Le projet en quelques mots",
                        en: "Le projet en quelques mots",
                    },
                    desc: {
                        fr: "<p>Christophe Barbraud présente les huit espèces de prédateurs marins choisies pour étudier l'évolution de la banquise dans les régions polaires.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/le-projet-en-quelques-mots",
                    //video: "https://www.youtube.com/watch?v=vbvzCFzMhnk",
                    video: "https://www.youtube.com/watch?v=oVJNzODlqc4",
                },
                {
                    slug: "perspectives-du-projet-sensei",
                    index: 3,
                    title: {
                        fr: "Perspectives du projet SENSEI",
                        en: "Perspectives du projet SENSEI",
                    },
                    desc: {
                        fr: "<p>Comment savoir ce qui se passe sous la banquise ? Comment étudier l’épaisseur de la banquise avec précision ? Découvrez les réponses dans cette vidéo.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/perspectives-du-projet-sensei",
                    //video: "https://www.youtube.com/watch?v=brQzDPf3ow8",
                    video: "https://www.youtube.com/watch?v=-oxY779Er5w",
                },
            ]
        },

        {
            title: {
                fr: "La banquise",
                en: "The ice floe",
            },
            slug: "la-banquise",
            content: [
                {
                    slug: "la-banquise-une-richesse-d-habitats",
                    index: 4,
                    title: {
                        fr: "La banquise, une richesse d'habitats",
                        en: "La banquise, une richesse d'habitats",
                    },
                    desc: {
                        fr: "<p>La banquise est un habitat bien plus complexe qu’il n’y parait, qui se doute qu’elle recèle d’une multitudes de paysages...</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/la-banquise-une-richesse-d-habitats",
                    //video: "https://www.youtube.com/watch?v=BS5x0ybwASg",
                    video: "https://www.youtube.com/watch?v=ywozouda5FU",
                },
                {
                    slug: "la-banquise-generalites",
                    index: 5,
                    title: {
                        fr: "La banquise, généralités",
                        en: "La banquise, généralités",
                    },
                    desc: {
                        fr: "<p>La banquise. Tout le monde connaît ce mot mais qui sait vraiment ce que c'est ? Martin Vancoppennolle nous l'explique simplement.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/la-banquise-generalites",
                    //video: "https://www.youtube.com/watch?v=Fq42h-8fvnk",
                    video: "https://www.youtube.com/watch?v=AsMwPGFbyZ0",
                },
                {
                    slug: "les-supers-pouvoirs-de-la-banquise",
                    index: 6,
                    title: {
                        fr: "Les supers pouvoirs de la banquise",
                        en: "Les supers pouvoirs de la banquise",
                    },
                    desc: {
                        fr: "<p>La banquise n'est pas seulement une grande étendue de glace, elle joue un rôle très important dans la régulation climatique mondiale et bien plus encore...</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/les-supers-pouvoirs-de-la-banquise",
                    //video: "https://www.youtube.com/watch?v=Hpno5_LkahY",
                    video: "https://www.youtube.com/watch?v=Wf4YLqNglKc",
                },
                {
                    slug: "etude-de-la-banquise",
                    index: 7,
                    title: {
                        fr: "Étude de la banquise",
                        en: "Étude de la banquise",
                    },
                    desc: {
                        fr: "<p>Dans le contexte du réchauffement climatique, l'étude de la banquise est plus que jamais cruciale. Martin Vancoppennolle précise quels sont les champs d'étude explorés par les scientifiques au cours des prochaines années et quels en sont les enjeux.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/etude-de-la-banquise",
                    //video: "https://www.youtube.com/watch?v=6VJ3GJlozxo",
                    video: "https://www.youtube.com/watch?v=jKtHHVws-4M",
                },
                {
                    slug: "la-banquise-une-oasis-de-vie",
                    index: 8,
                    title: {
                        fr: "La banquise, une oasis de vie",
                        en: "La banquise, une oasis de vie",
                    },
                    desc: {
                        fr: "<p>La banquise est souvent surnommée le désert blanc. Et pourtant... C'est un milieu qui abrite une multitude de formes de vie et la survie de nombreuses espèces animales en dépend.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/la-banquise-une-oasis-de-vie",
                    //video: "https://www.youtube.com/watch?v=u7pGO-LH7DM",
                    video: "https://www.youtube.com/watch?v=BQBagDPKwKE",
                },
                {
                    slug: "chaine-alimenaire-associee-a-la-banquise-relations-proies-predateurs",
                    index: 9,
                    title: {
                        fr: "Chaîne alimentaire associée à la banquise : relations proies-prédateurs",
                        en: "Chaîne alimentaire associée à la banquise relations proies-prédateurs",
                    },
                    desc: {
                        fr: "<p>La banquise est essentielle à la vie de nombreuses espèces, toute une chaîne alimentaire lui est associée... Découvrez ce fragile équilibre.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/chaine-alimentaire-associee-a-la-banquise-relations-proie-predateurs",
                    //video: "https://www.youtube.com/watch?v=nneEVNsaJmk",
                    video: "https://www.youtube.com/watch?v=OUZKWdS2GFk",
                },
            ]
        },

        {
            title: {
                fr: "Les prédateurs marins",
                en: "The marine predators",
            },
            slug: "les-predateurs-marins",
            content: [
                {
                    slug: "comment-etudier-l-alimentation-des-predateurs-marins",
                    index: 10,
                    title: {
                        fr: "Comment étudier l'alimentation des prédateurs marins",
                        en: "Comment étudier l'alimentation des prédateurs marins",
                    },
                    desc: {
                        fr: "<p>Comment étudier les habitudes alimentaires des prédateurs marins sous la banquise. Il existe plusieurs techniques pour percer un peu plus ce mystère.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/comment-etudier-l-alimentation-des-predateurs-marins",
                    //video: "https://www.youtube.com/watch?v=7ARJcYVqG7Y",
                    video: "https://www.youtube.com/watch?v=DS4IG42FB7Y",
                },
                {
                    slug: "les-elephants-de-mer-sentinelles-de-l-antarctique",
                    index: 11,
                    title: {
                        fr: "Les éléphants de mer, sentinelles de l'Antarctique",
                        en: "Les éléphants de mer, sentinelles de l'Antarctique",
                    },
                    desc: {
                        fr: "<p>Aujourd'hui, les éléphants de mer assurent quasiment l'essentiel des mesures de la température et de la salinité de l'océan Austral. Comment ? Découvrez-le dans cette vidéo.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/les-elephants-de-mer-sentinelles-de-l-antarctique",
                    //video: "https://www.youtube.com/watch?v=-nMY_JvE4bo",
                    video: "https://www.youtube.com/watch?v=6UgDFKs5v7w",
                },
                {
                    slug: "les-elephants-de-mer-et-les-polynies",
                    index: 12,
                    title: {
                        fr: "Les éléphants de mer et les polynies",
                        en: "Les éléphants de mer et les polynies",
                    },
                    desc: {
                        fr: "<p>Qu’est-ce qu’une polynie ? Quel lien existe-t-il entre ces trous d’eau dans la glace et les éléphants de mer ? Découvrez-le dans cette vidéo.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/les-elephants-de-mer-et-les-polynies",
                    //video: "https://www.youtube.com/watch?v=ttn7SF5g9zM",
                    video: "https://www.youtube.com/watch?v=Y02OMvKUFi0",
                },
                {
                    slug: "les-mysteres-du-regime-alimentaire-des-elephants-de-mer",
                    index: 13,
                    title: {
                        fr: "Les mystères du régime alimentaire des éléphants de mer",
                        en: "Les mystères du régime alimentaire des éléphants de mer",
                    },
                    desc: {
                        fr: "<p>Il est difficile de savoir ce qui se passe l’hiver sous la banquise, pourtant il semblerait que l’étude du régime alimentaire des éléphants de mer apporte quelques indices à la compréhension de ce mystère.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/les-mysteres-du-regime-alimentaire-des-elephants-de-mer",
                    //video: "https://www.youtube.com/watch?v=wrgleKeY3sI",
                    video: "https://www.youtube.com/watch?v=BRfiXjLoCMc",
                },
                {
                    slug: "le-manchot-adelie-eco-indicateur-du-milieu-banquise",
                    index: 14,
                    title: {
                        fr: "Le manchot adélie, éco-indicateur du milieu banquise",
                        en: "Le manchot adélie, éco-indicateur du milieu banquise",
                    },
                    desc: {
                        fr: "<p>La banquise est indispensable à la survie de nombreuses espèces. Mais quand elle est trop abondante ou trop rare cela peut impacter certaines espèces. Explications.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/le-manchot-adelie-eco-indicateur-du-milieu-banquise",
                    //video: "https://www.youtube.com/watch?v=pagWK_1TQwk",
                    video: "https://www.youtube.com/watch?v=yllHT1Mlubs",
                },
                {
                    slug: "le-guillemot-a-miroir-indicateur-du-changement-climatique",
                    index: 15,
                    title: {
                        fr: "Le guillemot à miroir, indicateur du changement climatique",
                        en: "Le guillemot à miroir, indicateur du changement climatique",
                    },
                    desc: {
                        fr: "<p>Le guillemot à miroir, un oiseau marin arctique est une espèce qui répond très fortement aux changements climatiques. George Divoky, l'étudie depuis les années 70 et ce qu'il nous révèle dans la vidéo est incroyable.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/le-guillemot-a-miroir-indicateur-du-changement-climatique",
                    //video: "https://www.youtube.com/watch?v=xrw0uQkl1Bo",
                    video: "https://www.youtube.com/watch?v=WxVqNR0rL9c",
                },
                {
                    slug: "le-guillemot-a-miroir-et-les-ours-polaires-une-histoire-climatique",
                    index: 16,
                    title: {
                        fr: "Le guillemot à miroir et les ours polaires, une histoire climatique",
                        en: "Le guillemot à miroir et les ours polaires, une histoire climatique",
                    },
                    desc: {
                        fr: "<p>George Divoky étudie le guillemot à miroir depuis une trentaine d’années. Un beau jour, il a observé un drôle de phénomène. Découvrez cette histoire étonnante...</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/le-guillemot-a-miroir-et-les-ours-polaires-une-histoire-climatique",
                    //video: "https://www.youtube.com/watch?v=mg50pekuVR4",
                    video: "https://www.youtube.com/watch?v=xGd_efF3FcU",
                },
                {
                    slug: "le-phoque-a-capuchon-un-phoque-de-l-extreme",
                    index: 17,
                    title: {
                        fr: "Le phoque à capuchon, un phoque de l'extrême",
                        en: "Le phoque à capuchon, un phoque de l'extrême",
                    },
                    desc: {
                        fr: "<p>Le phoque à capuchon est une espèce remarquable, pas seulement par son aspect physique. Il dispose de facultés exceptionnelles...</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/le-phoque-a-capuchon-un-phoque-de-l-extreme",
                    //video: "https://www.youtube.com/watch?v=i9YEAJDOOyU",
                    video: "https://www.youtube.com/watch?v=P1kb24vQw-M",
                },
            ]
        },

        {
            title: {
                fr: "Mesure des populations des prédateurs marins",
                en: "The marine predators populations measures",
            },
            slug: "mesure-des-populations-des-predateurs-marins",
            content: [
                {
                    slug: "le-bio-logging",
                    index: 18,
                    title: {
                        fr: "Le bio-logging",
                        en: "Le bio-logging",
                    },
                    desc: {
                        fr: "<p>Pour comprendre ce qui se passe sous la banquise, les scientifiques ont parfois recours à de drôles de techniques. Découvrez l’art du bio-logging.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/le-biologging",
                    //video: "https://www.youtube.com/watch?v=RCUNvaY8Xr8",
                    video: "https://www.youtube.com/watch?v=HavM09ISBmM",
                },
                {
                    slug: "comment-mesurer-l-impact-de-la-banquise-sur-les-populations-d-oiseaux-marins",
                    index: 19,
                    title: {
                        fr: "Comment mesurer l'impact de la banquise sur les populations d'oiseaux marins",
                        en: "Comment mesurer l'impact de la banquise sur les populations d'oiseaux marins",
                    },
                    desc: {
                        fr: "<p>Il est très difficile de prédire comment l’évolution de la banquise va impacter les populations animales. Pourtant les scientifiques disposent aujourd’hui de moyens inédits pour lire dans le futur. Explications.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/comment-mesurer-l-impact-de-la-banquise-sur-les-populations-d-oiseaux-marins",
                    //video: "https://www.youtube.com/watch?v=im7h-XAaEeQ",
                    video: "https://www.youtube.com/watch?v=JDfNZWXsYQ0",
                },
                {
                    slug: "comment-mesurer-l-abondance-des-especes-de-predateurs-marins",
                    index: 20,
                    title: {
                        fr: "Comment mesurer l'abondance des espèces de prédateurs marins",
                        en: "Comment mesurer l'abondance des espèces de prédateurs marins",
                    },
                    desc: {
                        fr: "<p>Pour suivre l'évolution d'une espèce au cours du temps, il est important de connaître son abondance. Le nombre d'individus de la population augmente-t-il ou diminue-t-il ? Pour cela, les scientifiques utilisent de nombreuses techniques, Stéphanie Jenouvrier nous en présente les principales.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/comment-mesurer-l-abondance-des-especes-de-predateurs-marins",
                    //video: "https://www.youtube.com/watch?v=fvR0bAksQC8",
                    video: "https://www.youtube.com/watch?v=7Z9SLoWwuVc",
                },
            ]
        },

        {
            title: {
                fr: "Stress et polluants dans les pôles",
                en: "Stress et polluants dans les pôles",
            },
            slug: "stress-et-polluants-dans-les-poles",
            content: [
                {
                    slug: "le-stress-chez-les-oiseaux-marins",
                    index: 21,
                    title: {
                        fr: "Le stress chez les oiseaux marins",
                        en: "Le stress chez les oiseaux marins",
                    },
                    desc: {
                        fr: "<p>Le stress n’est pas une réaction propre aux humains. Les oiseaux marins aussi sont soumis au stress tout au long de leur vie. Comment ? La réponse en images.</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/le-stress-chez-les-oiseaux-marins",
                    //video: "https://www.youtube.com/watch?v=mOEcqtsWkbQ",
                    video: "https://www.youtube.com/watch?v=FlhYR2_BLz0",
                },
                {
                    slug: "les-polluants-perturbateurs-endocriniens",
                    index: 22,
                    title: {
                        fr: "Les polluants, perturbateurs endocriniens",
                        en: "Les polluants, perturbateurs endocriniens",
                    },
                    desc: {
                        fr: "<p>Dans les pôles, certains polluants modifient le comportement reproducteur des oiseaux de mer. Comment en est-on arrivé là ?</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/les-polluants-perturbateurs-endocriniens",
                    //video: "https://www.youtube.com/watch?v=ndtu3YUhtK0",
                    video: "https://www.youtube.com/watch?v=nDOXb5e0Axk",
                },
                {
                    slug: "les-polluants-dans-les-poles",
                    index: 23,
                    title: {
                        fr: "Les polluants dans les pôles",
                        en: "Les polluants dans les pôles",
                    },
                    desc: {
                        fr: "<p> Comment se fait-il que les sources de pollution émises en Europe se retrouvent tôt ou tard dans les milieux polaires. Découvrez l’incroyable voyage des molécules chimiques...</p>",
                        en: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec nulla et metus eleifend tempor dictum vitae sapien. <strong>Morbi aliquam pretium mauris</strong>, ac accumsan urna varius in.</p>",
                    },
                    thumb: "/images/videos/les-polluants-dans-les-poles",
                    //video: "https://www.youtube.com/watch?v=_-w7wwE4NCg",
                    video: "https://www.youtube.com/watch?v=C5H6-AEaRfw",
                },
            ]
        },
    ],
};