import Reflux        from 'reflux';
import {TransitionActions} from "./TransitionStoreActions";


export class TransitionStore extends Reflux.Store {

    constructor(initialState = {}) {
        super();

        this.state = {
            transitioning: true,
            transitionFrom: window.location.pathname,
            transitionTo: "",
            navigationKey: "",
        };

        this.listenables = TransitionActions;
    }

    set(transitioning) {
        this.setState({transitioning});
    }

    handleHistory(from, to) {
        if(from) {
            this.setState({
                transitionFrom: from,
            });
        }

        if(to) {
            this.setState({
                transitionTo: to,
            });
        }
    }

    setKey(key) {
        this.setState({
            navigationKey: key,
        });
    }


}