export function getSiteOrigin() {
    return window.location.origin;
}

export function isProduction() {
    return window.location.href.indexOf("projetsensei.com") === -1 ? false : true;
}

export function getBasePath() {
    return window.location.origin === "https://www.martin-laxenaire.fr" ? "/test/sensei" : "";
}

export function shouldUseLanguages() {
    // right now we'll just handle the french version!
    return false;
    //return true;
}

export function stripTags(string) {
    return string.replace(/(<([^>]+)>)/ig,"");
}

export function getStripedMetaDesc(desc) {
    let stripedDesc = stripTags(desc);
    if(stripedDesc.length > 150) {
        stripedDesc = stripedDesc.substring(0, 150) + "...";
    }

    return stripedDesc;
}