import React from 'react';

import {getBasePath} from "../utils/config";

import ScrollComponentBase from './base/ScrollComponentBase';
import AnimatedTitle from "./AnimatedTitle";
import LinkBase from "./base/LinkBase";
import FluidButton from "./FluidButton";
import ArrowIcon from "./ArrowIcon";

import './HomeDesc.scss';

class HomeDesc extends ScrollComponentBase {

    static baseClassName = "HomeDesc";

    /*** React component lifecycle ***/

    didMount() {
        //console.log("Home intro did mount", this._scrollValue);
    }

    /*** Listeners ***/

    onScroll() {
        //console.log(this._scrollValue);
    }

    /*** Custom methods ***/

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;

        let {data, themesData} = this.props;

        let {language, visible} = this.state;

        let basePath = getBasePath();

        return (
            <section
                className={this.getMainCSSClass()}
                ref={(el) => this.registerElement(el)}
                id="presentation"
            >

                <div className="wrapper">

                    <div className={componentClass + "-inner"}>

                        <div className={componentClass + "-header"}>

                            { data && data.title &&
                            <h3 className={componentClass + "-title"}>
                                <AnimatedTitle
                                    animate={visible}
                                >
                                    {data.title[language]}
                                </AnimatedTitle>
                            </h3>
                            }

                            { data && data.button && themesData && themesData.list && themesData.list[0] && themesData.list[0].content &&  themesData.list[0].content[0] &&
                            <LinkBase to={basePath + "/" + language + "/" + themesData.list[0].slug + "/" + themesData.list[0].content[0].slug} className={componentClass + "-button"}>
                                <FluidButton
                                    color="#a7dde1"
                                    deformation="7"
                                >
                                    <div className={componentClass + "-button-inner"}>
                                        <ArrowIcon />
                                        <span>{data.button[language]}</span>
                                    </div>
                                </FluidButton>
                            </LinkBase>
                            }

                        </div>

                        { data && data.content &&
                        <div
                            dangerouslySetInnerHTML={{__html: data.content[language]}}
                            className={componentClass + "-content"}
                        />
                        }

                        { data && data.infoButton &&
                        <LinkBase to={basePath + "/" + language + "/informations"} className={componentClass + "-info-button"}>
                            <FluidButton
                                color="#a7dde1"
                                deformation="7"
                            >
                                <div className={componentClass + "-info-button-inner"}>
                                    <ArrowIcon />
                                    <span>{data.infoButton[language]}</span>
                                </div>
                            </FluidButton>
                        </LinkBase>
                        }

                    </div>
                </div>
            </section>
        );
    }
}

export default HomeDesc;
