import React from 'react';

import {getBasePath, getSiteOrigin} from "../utils/config";

import ScrollComponentBase from './base/ScrollComponentBase';
import AnimatedTitle from './AnimatedTitle';

import './ContactForm.scss';
import {MouseActions} from "../globals/MouseStoreActions";

class ContactForm extends ScrollComponentBase {

    static baseClassName = "ContactForm";

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            nameError: null,

            email: "",
            emailError: null,

            subject: "",
            subjectError: null,

            message: "",
            messageError: null,

            honeypot: "",

            formSuccess: null,
            formError: null,
        };
    }

    /*** React component lifecycle ***/

    didMount() {
        //console.log("Home intro did mount", this._scrollValue);
    }

    /*** Listeners ***/

    onScroll() {
        //console.log(this._scrollValue);
    }

    /*** Custom methods ***/

    onMouseEnter() {
        MouseActions.toggleHovering(true);
    }

    onMouseLeave() {
        MouseActions.toggleHovering(false);
    }

    /*** form ***/

    onInputChange(e) {
        let value = e.target.value;
        let input = e.target.getAttribute("name");

        this.setState({ [input]: value });
    }

    submitForm(e) {
        e.preventDefault();

        // reset error states
        this.setState({
            nameError: null,
            emailError: null,
            subjectError: null,
            messageError: null,

            formSuccess: null,
            formError: null,
        });

        let checkEmailRegEx = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]*)\.([a-z]{2,}(?:\.[a-z]{2})?)$/i;

        const API_URL = getSiteOrigin() + getBasePath() + "/php/contact/index.php";

        // name
        if(this.state.name === "") {
            this.setState({ nameError: true });
            return;
        }

        // email
        if(this.state.email === "") {
            this.setState({ emailError: true });
            return;
        }
        else if(!checkEmailRegEx.test(this.state.email)) {
            this.setState({ emailError: true });
            return;
        }

        // subject
        if(this.state.subject === "") {
            this.setState({ subjectError: true });
            return;
        }

        // message
        if(this.state.message === "") {
            this.setState({ messageError: true });
            return;
        }

        // honeypot
        if(this.state.honeypot !== "") {
            return;
        }

        // submit form
        let formData = {
            name: this.state.name,
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message.replace(/(?:\r\n|\r|\n)/g, '<br>'),
        };

        let self = this;

        // handling ajax
        let xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 0)) {
                let jsonResponse = xhr.response;
                let response = JSON.parse(jsonResponse);

                if(response.sent) {
                    self.setState({
                        name: "",
                        email: "",
                        subject: "",
                        message: "",
                        formSuccess: true
                    });
                }
                else {
                    self.setState({ formError: true });
                }
            }
        };

        xhr.addEventListener('error', function() {
            self.setState({ formError: true });
        });

        xhr.open("POST", API_URL, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(formData));
    }

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;

        let {language, visible, nameError, emailError, subjectError, messageError, formSuccess, formError} = this.state;

        let {data} = this.props;

        let labels = {
            name: {
                fr: "Nom",
                en: "Name",
            },
            nameError: {
                fr: "Veuillez renseigner votre nom.",
                en: "Please fill in your name.",
            },

            email: {
                fr: "Email",
                en: "Email",
            },
            emailError: {
                fr: "Veuillez renseigner un email valide.",
                en: "Please provide a valid email.",
            },

            subject: {
                fr: "Sujet",
                en: "Subject",
            },
            subjectError: {
                fr: "Veuillez renseigner un sujet.",
                en: "Please provide the subject of your message.",
            },

            message: {
                fr: "Message",
                en: "Message",
            },
            messageError: {
                fr: "Veuillez écrire un message.",
                en: "Please write a message.",
            },

            submit: {
                fr: "Envoyer",
                en: "Send",
            },

            formSuccess: {
                fr: "Merci, votre message a bien été envoyé.",
                en: "Your message has been successfully sent. Thank you.",
            },
            formError: {
                fr: "Il y a eu une erreur lors de l'envoi de votre message. Veuillez réessayer",
                en: "There has been an error while sending your message. Please try again.",
            },
        };

        return (
            <section
                className={this.getMainCSSClass()}
                ref={(el) => this.registerElement(el)}
                id="contact"
            >
                <div className="wrapper">
                    <form className={componentClass + "-inner"}>

                        <h2 className={componentClass + "-title ProjectPage-subtitle"}>
                            <AnimatedTitle
                                animate={visible}
                            >
                                {data.title[language]}
                            </AnimatedTitle>
                        </h2>

                        <div className={nameError ? componentClass + "-field " + componentClass + "-field--error" : componentClass + "-field"}>

                            <div className={componentClass + "-field-label"}>
                                <label htmlFor="name">
                                    {labels.name[language]}
                                </label>
                            </div>

                            <div className={componentClass + "-field-input"}>
                                <input type="text" value={this.state.name} name="name" onChange={(e) => this.onInputChange(e)} />
                            </div>

                            { nameError &&
                                <div className={componentClass + "-field-error"}>
                                    {labels.nameError[language]}
                                </div>
                            }

                        </div>

                        <div className={emailError ? componentClass + "-field " + componentClass + "-field--error" : componentClass + "-field"}>

                            <div className={componentClass + "-field-label"}>
                                <label htmlFor="email">
                                    {labels.email[language]}
                                </label>
                            </div>

                            <div className={componentClass + "-field-input"}>
                                <input type="email" value={this.state.email} name="email" onChange={(e) => this.onInputChange(e)} />
                            </div>

                            { emailError &&
                            <div className={componentClass + "-field-error"}>
                                {labels.emailError[language]}
                            </div>
                            }

                        </div>

                        <div className={subjectError ? componentClass + "-field " + componentClass + "-field--error" : componentClass + "-field"}>

                            <div className={componentClass + "-field-label"}>
                                <label htmlFor="subject">
                                    {labels.subject[language]}
                                </label>
                            </div>

                            <div className={componentClass + "-field-input"}>
                                <input type="text" value={this.state.subject} name="subject" onChange={(e) => this.onInputChange(e)} />
                            </div>

                            { subjectError &&
                            <div className={componentClass + "-field-error"}>
                                {labels.subjectError[language]}
                            </div>
                            }

                        </div>

                        <div className={messageError ? componentClass + "-field " + componentClass + "-field--error" : componentClass + "-field"}>

                            <div className={componentClass + "-field-label"}>
                                <label htmlFor="message">
                                    {labels.message[language]}
                                </label>
                            </div>

                            <div className={componentClass + "-field-input"}>
                                <textarea value={this.state.message} name="message" onChange={(e) => this.onInputChange(e)} />
                            </div>

                            { messageError &&
                            <div className={componentClass + "-field-error"}>
                                {labels.messageError[language]}
                            </div>
                            }

                        </div>


                        <div className={componentClass + "-honeypot"}>
                            <input type="text" name="honeypot" onChange={(e) => this.onInputChange(e)} />
                        </div>


                        <div className={componentClass + "-field"}>

                            <div className={componentClass + "-field-input"}>
                                <button
                                    onClick={(e) => this.submitForm(e)}
                                    onMouseEnter={() => this.onMouseEnter()}
                                    onMouseLeave={() => this.onMouseLeave()}
                                >
                                    {labels.submit[language]}
                                </button>
                            </div>

                        </div>

                        { formSuccess &&
                        <div className={componentClass + "-success"}>
                            {labels.formSuccess[language]}
                        </div>
                        }

                        { formError &&
                        <div className={componentClass + "-error"}>
                            {labels.formError[language]}
                        </div>
                        }

                    </form>
                </div>
            </section>
        );
    }
}

export default ContactForm;