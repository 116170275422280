import React from 'react';
import anime from 'animejs';
import isMobile from 'ismobilejs';

import './FluidButton.scss';

class FluidButton extends React.Component {
    static baseClassName = "FluidButton";

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
        };

        this._mousePos = {
            x: 0,
            y: 0,
        };
        this._ctx = null;

        this._mouseEffect = 0;

        let randomStrength = props.deformation ? props.deformation * 0.75 : 7.5;
        this._randomAngles = {
            topLeft: Math.random() * randomStrength - randomStrength / 4,
            topRight: Math.random() * randomStrength - randomStrength / 1.5,
            bottomRight: (Math.random() * randomStrength - randomStrength / 1.5) / 2,
            //bottomRight: 0,
            bottomLeft: Math.random() * randomStrength - randomStrength / 4,
        };
    }

    /*** React component lifecycle ***/

    componentDidMount() {
        this.setState({
            isMobile: isMobile.any
        });

        if(this._canvas) {
            this._setupCanvas();
        }
    }

    componentDidUpdate() {
        this._onResize();
    }

    componentWillUnmount() {
        if(this._button) {
            this._button.removeEventListener("mousemove", this._mouseListener, {passive: true});

            this._button.removeEventListener("mouseenter", this._mouseEnter, {passive: true});
            this._button.removeEventListener("mouseleave", this._mouseLeave, {passive: true});
        }

        window.removeEventListener("resize", this._resize);

        anime.remove(this);
    }

    /*** Custom methods ***/

    _handleMouse(e) {
        this._mousePos = {
            x: e.clientX - this._buttonBoundingRect.left,
            y: e.clientY - this._buttonBoundingRect.top,
        };

        if(this._canvas) {
            this._drawCanvas();
        }
    }

    _onMouseEnter() {
        if(this._canvas) {
            anime.remove(this);

            anime({
                targets: this,
                _mouseEffect: 1,
                easing: "easeOutCubic",
                duration: 350,
            });
        }
    }

    _onMouseLeave() {
        if(this._canvas) {
            anime.remove(this);

            let self = this;

            anime({
                targets: this,
                _mouseEffect: 0,
                easing: "easeOutCubic",
                duration: 350,
                update: function () {
                    self._drawCanvas();
                }
            });
        }
    }

    _onResize() {
        if(this._canvas) {
            this._buttonBoundingRect = this._button.getBoundingClientRect();

            this._canvas.width = this._buttonBoundingRect.width;
            this._canvas.height = this._buttonBoundingRect.height;

            this._drawCanvas();
        }
    }

    _setupCanvas() {
        this._button = this._canvas.parentNode;
        this._buttonBoundingRect = this._button.getBoundingClientRect();

        let devicePixelRatio = window.devicePixelRatio || 1;

        this._canvas.width = this._buttonBoundingRect.width * devicePixelRatio;
        this._canvas.height = this._buttonBoundingRect.height * devicePixelRatio;
        this._ctx = this._canvas.getContext("2d");
        this._ctx.scale(devicePixelRatio, devicePixelRatio);
        this._ctx.translate(0.5, 0.5);

        this._mouseListener = this._handleMouse.bind(this);

        this._button.addEventListener("mousemove", this._mouseListener, {passive: true});

        this._mouseEnter = this._onMouseEnter.bind(this);
        this._mouseLeave = this._onMouseLeave.bind(this);

        this._button.addEventListener("mouseenter", this._mouseEnter, {passive: true});
        this._button.addEventListener("mouseleave", this._mouseLeave, {passive: true});

        this._resize = this._onResize.bind(this);
        window.addEventListener("resize", this._resize);

        //this._animate();
        this._drawCanvas();
    }

    _drawCanvas() {
        let width = this._canvas.width;
        let height = this._canvas.height;

        let verticalPadding = 20;
        let horizontalPadding = 20;
        let radius = (height - (verticalPadding * 2)) / 2;

        let lineWidth = 2;

        let mousePos = {
            x: this._mousePos.x ? Math.min(Math.max(radius + horizontalPadding + lineWidth, this._mousePos.x), width - (radius + horizontalPadding + lineWidth)) : width / 2,
            y: this._mousePos.y ? this._mousePos.y : height / 2,
        };

        let distances = {
            centerX: Math.abs((mousePos.x - (radius + horizontalPadding + lineWidth)) / (width - (radius + horizontalPadding + lineWidth) * 2) * 2 - 1),

            clampedLeft: Math.min(1, Math.max(0, (mousePos.x - (radius + horizontalPadding + lineWidth)) / (width - (radius + horizontalPadding + lineWidth) * 2))),

            clampedRight: 1 - Math.min(1, Math.max(0, (mousePos.x - (radius + horizontalPadding + lineWidth)) / (width - (radius + horizontalPadding + lineWidth) * 2))),

            clamped: Math.max(Math.min(mousePos.x, width - (radius + horizontalPadding + lineWidth)), radius + horizontalPadding + lineWidth),
        };


        //let ratio = (radius / 3) * this._mouseEffect;
        let deformation = this.props.deformation || 10;
        let ratio = deformation * Math.min(1, this._mouseEffect);

        this._ctx.strokeStyle = this.props.color || "white";
        this._ctx.lineWidth = lineWidth;
        this._ctx.lineCap = "round";

        this._ctx.clearRect(0, 0, width, height);

        this._ctx.beginPath();

        this._ctx.moveTo(radius + horizontalPadding, verticalPadding - distances.clampedRight * ratio + this._randomAngles.topLeft);

        let rightTopCurve = {
            first: {
                x: width - (radius + horizontalPadding) + ratio * distances.clampedLeft,
                y: verticalPadding - distances.clampedLeft * ratio + this._randomAngles.topLeft,
            },
            second: {
                x: width - horizontalPadding + distances.clampedLeft * ratio,
                y: verticalPadding + this._randomAngles.topRight,
            },
            point: {
                x: width - horizontalPadding + distances.clampedLeft * ratio,
                y: height / 2 + this._randomAngles.topRight,
            }
        };

        let rightBottomCurve = {
            first: {
                x: width - horizontalPadding + distances.clampedLeft * ratio,
                y: height - verticalPadding + distances.clampedLeft * ratio + this._randomAngles.topRight,
            },
            second: {
                x: width - (radius + horizontalPadding),
                y: height - verticalPadding + distances.clampedLeft * ratio + this._randomAngles.bottomRight,
            },
            point: {
                x: width - (radius + horizontalPadding),
                y: height - verticalPadding + distances.clampedLeft * ratio + this._randomAngles.bottomRight,
            }
        };

        let leftBottomCurve = {
            first: {
                x: radius + horizontalPadding,
                y: height - verticalPadding + distances.clampedRight * ratio + this._randomAngles.bottomRight,
            },
            second: {
                x: horizontalPadding - distances.clampedRight * ratio,
                y: height - verticalPadding + distances.clampedRight * ratio + this._randomAngles.bottomLeft,
            },
            point: {
                x: horizontalPadding - distances.clampedRight * ratio,
                y: height / 2 + this._randomAngles.bottomLeft,
            }
        };

        let leftTopCurve = {
            first: {
                x: horizontalPadding - distances.clampedRight * ratio,
                y: height / 2 + this._randomAngles.bottomLeft,
            },
            second: {
                x: horizontalPadding - distances.clampedRight * ratio,
                y: verticalPadding - distances.clampedRight * ratio + this._randomAngles.topLeft,
            },
            point: {
                x: radius + horizontalPadding,
                y: verticalPadding - distances.clampedRight * ratio + this._randomAngles.topLeft,
            }
        };

        // TODO remove topLeft, topRight, bottomRight, bottomLeft??
        let bezierCurves = [
            rightTopCurve, rightBottomCurve,
            leftBottomCurve, leftTopCurve
        ];

        for(let i = 0; i < bezierCurves.length; i++) {
            let curve = bezierCurves[i];
            this._ctx.bezierCurveTo(
                Math.floor(curve.first.x * 100) / 100, Math.floor(curve.first.y * 100) / 100,
                Math.floor(curve.second.x * 100) / 100, Math.floor(curve.second.y * 100) / 100,
                Math.floor(curve.point.x * 100) / 100, Math.floor(curve.point.y * 100) / 100,
            );
        }

        this._ctx.closePath();

        if(this.props.background) {
            this._ctx.fillStyle = this.props.background;
            this._ctx.fill();
        }

        this._ctx.stroke();
    }


    // get our DOM ref
    registerElement(el) {
        this._canvas = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;
        let mainClass = componentClass;
        if(this.state.isMobile) {
            mainClass += " " + componentClass + "--mobile";
        }

        return (
            <button
                className={mainClass}
            >
                <span className={componentClass + "-inner"}>
                    {this.props.children}
                </span>

                { !this.state.isMobile &&
                <canvas
                    ref={(el) => this.registerElement(el)}
                />
                }
            </button>
        );
    }
}

export default FluidButton;
