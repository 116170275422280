/**
 *
 * @author Fractal-IT
 */
import React from 'react';
//import {Link} from 'react-router-dom';

import ComponentBase from './base/ComponentBase';
import LinkBase from './base/LinkBase';
import FluidButton from './FluidButton';

import {getBasePath} from '../utils/config';

import './ChapterThumb.scss';

class ChapterThumb extends ComponentBase {

    static baseClassName = "ChapterThumb";

    _mounted = false;

    didMount() {
        this._mounted = true;
    }

    willUnmount() {
        this._mounted = false;
    }

    /*** Custom methods ***/

    onThumbEnter(e, i) {
        if(this._el && this._mounted) {
            this.props.onThumbEnter && this.props.onThumbEnter(i);
        }
    }

    onThumbLeave(e, i) {
        if(this._el && this._mounted) {
            this.props.onThumbLeave && this.props.onThumbLeave(i);
        }
    }

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;

        let data = this.props.data;
        let language = this.state.language;

        let basePath = getBasePath();

        return (
            <article
                className={componentClass}
                ref={(el) => this.registerElement(el)}
                onMouseEnter={(e) => this.onThumbEnter(e, this.props.thumbIndex)}
                onMouseLeave={(e) => this.onThumbLeave(e, this.props.thumbIndex)}
            >
                {data.slug &&
                <LinkBase to={basePath + "/" + language + "/" + this.props.themeSlug + "/" + data.slug} className={componentClass + "-inner"}>

                    {data && data.thumb &&
                    <figure className={componentClass + "-image"}>

                        <div className={componentClass + "-image-content"}>
                            <FluidButton
                                color="#a7dde1"
                                deformation={10}
                            >
                                <span className={componentClass + "-image-play"}>Lire la vidéo</span>
                            </FluidButton>
                        </div>

                        <img src={basePath + data.thumb + "-low.jpg"} width="800" height="450" alt={(data && data.title && data.title[language]) || ""} />
                    </figure>
                    }

                    {data && data.title &&
                    <h3 className={componentClass + "-title"}>
                        <span className={componentClass + "-title-index"}>
                            Ep. {data.index}
                        </span>
                        <span>
                            {data.title[language]}
                        </span>
                    </h3>
                    }

                    {data && data.desc &&
                    <div
                        dangerouslySetInnerHTML={{__html: data.desc[language]}}
                        className={componentClass + "-desc"}
                    />
                    }
                </LinkBase>
                }

                {!data.slug &&
                    <div className={componentClass + "-inner"}>
                        { data && data.thumb &&
                            <figure className={componentClass + "-image"}>

                                <div className={componentClass + "-image-content"}>
                                    <FluidButton
                                        color="#a7dde1"
                                        deformation={10}
                                    >
                                        <span className={componentClass + "-image-play"}>Lire la vidéo</span>
                                    </FluidButton>
                                </div>

                                <img src={basePath + data.thumb + "-low.jpg"} width="800" height="450" alt={(data && data.title && data.title[language]) || ""} />
                            </figure>
                        }

                        { data && data.title &&
                            <h3 className={componentClass + "-title"}>
                                <span className={componentClass + "-title-index"}>
                                    Ep. {data.index}
                                </span>
                                <span>
                                    {data.title[language]}
                                </span>
                            </h3>
                        }

                        { data && data.desc &&
                            <div
                                dangerouslySetInnerHTML={{__html: data.desc[language]}}
                                className={componentClass + "-desc"}
                            />
                        }
                    </div>
                }
            </article>
        );
    }
}

export default ChapterThumb;
