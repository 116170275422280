import React from 'react';

import LinkBase from './base/LinkBase';

import ComponentBase from './base/ComponentBase';
import FluidButton from './FluidButton';

/*** DATA ***/
import {themesData} from '../data/themes.js';

import {getBasePath} from '../utils/config';

import './ChapterShortNavigation.scss';

class ChapterShortNavigation extends ComponentBase {

    static baseClassName = "ChapterShortNavigation";

    /*** React component lifecycle ***/

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.chapter.index !== this.props.chapter.index;
    }

    /*** Custom methods ***/

    onLinkClicked() {
        this.props.onLinkClicked && this.props.onLinkClicked();
    }

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;

        let basePath = getBasePath();

        let language = this.state.language;

        let {chapter, history, delay} = this.props;

        let chapterIndex = chapter.index;
        let prevChapter, prevChapterThemeSlug, nextChapter, nextChapterThemeSlug;
        for(let i = 0; i < themesData.list.length; i++) {
            for(let j = 0; j < themesData.list[i].content.length; j++) {
                if(themesData.list[i].content[j].index === chapterIndex - 1) {
                    prevChapter = themesData.list[i].content[j];
                    prevChapterThemeSlug = themesData.list[i].slug;
                }
                else if(themesData.list[i].content[j].index === chapterIndex + 1) {
                    nextChapter = themesData.list[i].content[j];
                    nextChapterThemeSlug = themesData.list[i].slug;
                }
            }
        }

        return (
            <nav
                className={componentClass}
                ref={(el) => this.registerElement(el)}
            >
                <div className={componentClass + "-previous"}>
                {
                    prevChapter && prevChapter.index &&
                    <LinkBase
                        to={basePath + "/" + language + "/" + prevChapterThemeSlug + "/" + prevChapter.slug}
                        className={componentClass + "-previous-link"}

                        history={history}
                        delay={delay}
                        onDelayedClick={() => this.onLinkClicked()}
                    >
                        <FluidButton
                            deformation={4}
                            color="#a7dde1"
                        >
                            précédent
                        </FluidButton>
                        <div className={componentClass + "-previous-chapter"}>
                            <div className={componentClass + "-previous-chapter-index"}>
                                Ep. {prevChapter.index}
                            </div>
                            <div className={componentClass + "-previous-chapter-title"}>
                                {prevChapter.title[language]}
                            </div>
                        </div>
                    </LinkBase>
                }
                </div>

                <div className={componentClass + "-next"}>
                    {
                        nextChapter && nextChapter.index &&
                        <LinkBase
                            to={basePath + "/" + language + "/" + nextChapterThemeSlug + "/" + nextChapter.slug}
                            className={componentClass + "-next-link"}

                            history={history}
                            delay={delay}
                            onDelayedClick={() => this.onLinkClicked()}
                        >
                            <FluidButton
                                deformation={4}
                                color="#a7dde1"
                            >
                                suivant
                            </FluidButton>
                            <div className={componentClass + "-next-chapter"}>
                                <div className={componentClass + "-next-chapter-index"}>
                                    Ep. {nextChapter.index}
                                </div>
                                <div className={componentClass + "-next-chapter-title"}>
                                    {nextChapter.title[language]}
                                </div>
                            </div>
                        </LinkBase>
                    }
                </div>
            </nav>
        );
    }
}

export default ChapterShortNavigation;
