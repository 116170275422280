import React from 'react';
import Reflux from 'reflux';
import PropTypes from 'prop-types';

import TeamList from '../components/TeamList';
import PartnersLists from '../components/PartnersLists';
import ContactForm from '../components/ContactForm';

/*** DATA ***/
import {projectData} from '../data/project.js';

/*** STYLES ***/
import './ProjectPage.scss';

class ProjectPage extends Reflux.Component {

    static contextTypes = {
        getScrollbar: PropTypes.func
    };

    _mounted = false;

    constructor(props) {
        super(props);

        this.state = {
            scrollbar: null,
        };

        //this.store = TransitionStore;
    }

    /*** React component lifecycle ***/

    componentDidMount() {
        this._mounted = true;
        this.context.getScrollbar((scrollbar) => {
            if(this._mounted) {
                this.setState({scrollbar: scrollbar});
            }
        });
    }

    componentWillUpdate(nextProps, nextState) {
        if(!nextProps.transitioning && nextState.scrollbar && this._mounted) {
            this.scrollToSection(nextState.scrollbar);
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    scrollToSection(scrollbar) {
        let hash = window.location.hash;
        if(hash && this._el) {
            let targetDiv = this._el.querySelector(hash);

            let self = this;

            if(targetDiv) {
                setTimeout(function() {
                    if(scrollbar && self._mounted) {
                        scrollbar.update();
                        // reset scroll
                        scrollbar.setPosition(0, 0);
                        // apply new scroll
                        scrollbar.setPosition(0, targetDiv.getBoundingClientRect().top - 40);
                    }
                    else if(self._mounted) {
                        window.scrollTo(0, targetDiv.getBoundingClientRect().top - 40);
                    }
                }, 0);
            }
        }
    }

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let pageClass = "ProjectPage";

        let data = projectData;

        return (
            <div
                className={pageClass}
                ref={(el) => this.registerElement(el)}
            >

                <div className={pageClass + "-inner"}>

                    <TeamList
                        data={data}
                    />

                    <PartnersLists
                        data={data.partners}
                    />

                    <ContactForm
                        data={data.contact}
                    />
                </div>
            </div>
        );
    }
}

export default ProjectPage;
