export const homeData = {
    intro: {
        title: {
            fr: "Projet SENSEI",
            en: "SENSEI project",
        },
        subtitle: {
            fr: "Sentinels of the Sea Ice",
            en: "Sentinels of the Sea Ice",
        },
    },

    desc: {
        title: {
            fr: "Présentation",
            en: "Introduction",
        },
        button: {
            fr: "Commencer la lecture",
            en: "Start watching",
        },
        content: {
            /*fr: "<p>S'il existe un écosystème particulièrement sensible au réchauffement de la planète, c'est bien la banquise : cette couche de glace qui se forme à la surface des océans lorsque les températures sont suffisamment froides.</p><p>Depuis les années 1980, les scientifiques observent <strong>une diminution de 3,8% par décennie de la superficie de la glace de mer en Arctique et une augmentation de 1,5% en Antarctique</strong>. Toutes les projections scientifiques du Groupe d'experts intergouvernemental sur l'évolution du climat (GIEC) prévoient <strong>une accélération de cette fonte dans les prochaines années</strong>.</p><p>Quelles sont et quelles seront les conséquences de cette réduction de la banquise sur les espèces qui y vivent, y chassent ou en dépendent pour leur reproduction ?<br />C'est ce que cherche à comprendre le projet SENSEI, qui réunit 13 équipes de chercheurs issus de 6 pays.</p><p>Comment ? En se servant de <strong>8 espèces de prédateurs marins supérieurs</strong> comme indicateurs de l’évolution de ce milieu, <strong>en Arctique et en Antarctique</strong>.</p>",*/
            fr: "<h4>Bienvenue sur le site éducatif du projet SENSEI ! </h4><p>A travers <strong>23 petites vidéos</strong>, nous vous invitons à découvrir à quel point <strong>la banquise est importante dans l’équilibre de notre planète.</strong></p><p>Il ne s’agit pas seulement d’une fine couche de glace qui se forme à la surface des océans lorsque les températures sont suffisamment froides.<br />La banquise est aujourd’hui <strong>un des plus grands marqueurs du réchauffement climatique.</strong></p><p>Depuis les années 1980, les scientifiques observent <strong>une diminution de 3,8% par décennie de la superficie de la banquise en Arctique et une augmentation de 1,5% en Antarctique.</strong> Toutes les projections scientifiques du Groupe d'experts intergouvernemental sur l'évolution du climat (GIEC) prévoient <strong>une accélération de cette fonte dans les prochaines années.</strong></p><p>Quelles sont et quelles seront les conséquences de cette réduction de la banquise sur les espèces qui y vivent, y chassent ou en dépendent pour leur reproduction ?<br />C'est ce que cherche à comprendre le projet SENSEI, qui réunit <strong>13 équipes de chercheurs issus de 6 pays.</strong></p><p>Comment ? En se servant de <strong>8 espèces de prédateurs marins supérieurs en Arctique et en Antarctique</strong>, comme indicateurs de l’évolution de ce milieu.</p><p>Embarquez sans plus tarder dans l’aventure polaire SENSEI !</p>",

            en: "<p>Donec commodo leo sed odio malesuada ultrices. Sed finibus ullamcorper efficitur. In et feugiat arcu. Interdum et malesuada fames ac ante ipsum primis in faucibus. <strong>Integer feugiat</strong>, elit sed dapibus commodo, mi neque tristique massa, nec mattis turpis neque a magna. Nam felis tortor, aliquet in vulputate ut, faucibus eu massa.</p>",
        },
        infoButton: {
            fr: "Plus d'informations sur le projet",
            en: "More informations about the project",
        },
    },
};