/**
 *
 * @author Fractal-IT
 */
import React from 'react';
import {Link} from 'react-router-dom';

import {MouseActions}      from "../../globals/MouseStoreActions";

class LinkBase extends Link {

    static contextTypes = Link.contextTypes;

    constructor(props) {
        super(props);

        this._delayTimer = null;
    }

    componentWillUnmount() {
        if(this._delayTimer) {
            clearTimeout(this._delayTimer);
        }
    }

    onMouseEnter() {
        MouseActions.toggleHovering(true);
    }

    onMouseLeave() {
        MouseActions.toggleHovering(false);
    }

    handleClick(e) {
        if(this.props.history && this.props.delay) {
            e.preventDefault();

            let {history, delay, to, onDelayedClick} = this.props;
            onDelayedClick && onDelayedClick();
            this._delayTimer = setTimeout(function() {
                history.push(to);
            }, delay);
        }
    }

    registerLink(el) {
        this._el = el;
    }

    render() {

        return (
            <Link
                //{...this.props}
                to={this.props.to}
                className={this.props.className}

                onMouseEnter={() => this.onMouseEnter()}
                onMouseLeave={() => this.onMouseLeave()}
                ref={(el) => this.registerLink(el)}
                onClick={(e) => this.handleClick(e)}
            >
                {this.props.children}
            </Link>
        );
    }
}

export default LinkBase;