import React from 'react';

import './App.scss';

import MainContent from "./MainContent";

class App extends React.Component {

    render() {

        return (
            <div className="App">

                <MainContent />

            </div>
        );
    }
}

export default App;
