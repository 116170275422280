/**
 *
 * @author Fractal-IT
 */
import React from 'react';
import {Helmet} from "react-helmet";
import {Router} from 'react-router-dom';
import isMobile from 'ismobilejs';


import history from './utils/history';

/*** COMPONENTS ***/
import Header from './components/Header';
import LoadingScreen from "./components/LoadingScreen";
import CustomPointer from "./components/CustomPointer";
//import WebGLBackground from "./components/WebGLBackground";
import RoutesBase from './RoutesBase';

import {getBasePath, getSiteOrigin, shouldUseLanguages, isProduction} from './utils/config';

import {LanguageActions} from "./globals/LanguageStoreActions";
import {TransitionStore} from "./globals/TransitionStore";


class MainContent extends React.Component {

    _mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            siteLoaded: false,
            menuShown: false,
            scrollbar: null,
            isMobile: false,
        };

        this.store = TransitionStore;
    }

    componentWillMount() {
        let isProd = isProduction();
        if(isProd) {
            console.log = function() {};
            console.warn = function() {};
            console.info = function() {};
            console.debug = function() {};
            console.error = function() {};
        }

        // let's say our default language is french
        let currentLanguage = "fr";

        // check for user browser default language
        let userLanguage = navigator.language || navigator.userLanguage;
        console.log("navigator language", userLanguage);

        // if it's not french, then we'll set our default language to english
        if(userLanguage.indexOf("fr") === -1) {
            currentLanguage = "en";
        }

        let basePath = getBasePath();

        // now let's check if our language param is set in our current url
        let pathname = window.location.pathname;
        pathname = pathname.replace(basePath, "");

        let useLanguage = shouldUseLanguages();

        // looks like we don't have a language param in the url, rewrite url with our current language param
        if(pathname.length > 3) {
            if(pathname.substring(0, 4) !== "/en/" && pathname.substring(0, 4) !== "/fr/") {
                console.log("language not set in url detected via substring", pathname.substring(0, 4));

                if(!useLanguage) {
                    currentLanguage = "fr";
                }
                history.replace(basePath + "/" + currentLanguage + pathname);
            }
            else if(pathname.substring(0, 4) === "/en/") {
                currentLanguage = "en";
            }
        }
        else if(pathname.length <= 3) {
            if(pathname.substring(0, 3) !== "/en" && pathname.substring(0, 3) !== "/fr") {

                if(!useLanguage) {
                    currentLanguage = "fr";
                }
                history.replace(basePath + "/" + currentLanguage + pathname);
            }
            else if(pathname.substring(0, 3) === "/en") {
                currentLanguage = "en";
            }
        }

        // set our global current language
        if(currentLanguage === "en" && useLanguage) {
            setTimeout(function() {
                LanguageActions.set("en");
            }, 0);
        }
    }

    componentDidMount() {
        this._mounted = true;
        if(this._mounted) {
            this.setState({
                isMobile: isMobile.any
            });
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    onMenuChange(menuState) {
        //this.setState({ menuShown: menuState });
    }

    onTransitioning() {
        //this.setState({ menuShown: menuState });
        console.log("transition!");
        if(this._mounted) {
            this.setState({ menuShown: true });
        }

        let self = this;
        setTimeout(function() {
            if(self._mounted) {
                self.setState({menuShown: false});
            }
        }, 1250);
    }

    onSiteLoaded() {
        if(this._mounted) {
            this.setState({
                siteLoaded: true,
            });
        }
    }

    getScrollbar(scrollbar) {
        if(this._mounted) {
            this.setState({scrollbar: scrollbar});
        }
    }

    render() {
        let siteOrigin = getSiteOrigin();
        let basePath = getBasePath();
        let {scrollbar, isMobile} = this.state;

        let mainClass = "Main";
        if(this.state.isMobile) {
            mainClass += " Main--mobile";
        }

        if(this.state.transitioning) {
            mainClass += " Main--transitioning";
        }

        if(!this.state.siteLoaded) {
            mainClass += " Main--loading";
        }

        return (
            <main className={mainClass}>

                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
                    <title>Projet Sensei</title>
                    <link rel="canonical" href={siteOrigin + basePath} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={siteOrigin + basePath} />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:creator" content="@webdesign_ml" />
                    <meta name="twitter:title" content="Projet Sensei" />
                </Helmet>

                <Router history={history} basename={basePath}>

                    <Header
                        onMenuChange={(menuState) => this.onMenuChange(menuState)}
                        onTransitioning={() => this.onTransitioning()}
                        scrollbar={scrollbar}
                    />

                    { this.state.siteLoaded &&
                        <RoutesBase
                            history={history}
                            isMobile={isMobile}
                            getScrollbar={(scrollbar) => this.getScrollbar(scrollbar)}
                        />
                    }

                    <LoadingScreen
                        onLoadComplete={() => this.onSiteLoaded()}
                    />

                    {/*<WebGLBackground
                        menuShown={this.state.menuShown}
                    />*/}

                    {!this.state.isMobile &&
                    <CustomPointer />
                    }

                </Router>
            </main>
        );
    }
}

export default MainContent;
