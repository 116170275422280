import React, {Component} from 'react';

import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import './VideoPlayer.scss';

class VideoPlayer extends Component {
    static baseClassName = "VideoPlayer";

    componentDidMount() {
        if(this._el && this.props.video) {

            const options = {
                autoplay: true
            };
            this.player = new Plyr(this._el, options);

            /*this.player.source = {
                type: 'video',
                sources: [
                    {
                        src: this.props.video,
                        provider: 'youtube',
                    }
                ],
            };

            this.player.play();*/

            let self = this;

            self.player.on('ended', event => {
                self.props.onEnd && self.props.onEnd();
            });

            self.player.on('timeupdate', event => {
                self.props.onTimeUpdate && self.props.onTimeUpdate(self.player.currentTime, self.player.duration);
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.props.video !== nextProps.video) {
            console.log("we're going to another video", nextProps.video);

            if(this.player) {
                this.player.source = {
                    type: 'video',
                    sources: [
                        {
                            src: nextProps.video,
                            provider: 'youtube',
                        }
                    ],
                };

                this.player.play();
            }

            return false;
        }
        else {
            return true;
        }
    }

    componentWillUnmount() {
        console.log("video player will unmount");
        if(this.player) {
            this.player.pause();
            this.player.destroy();
        }
    }

    /*** Custom methods ***/

    // get our DOM ref
    registerElement(el) {
        this._el = el;
    }

    render() {
        let componentClass = this.constructor.baseClassName;

        let video = this.props.video;

        if(!video) return <div />;

        return (
            <figure
                className={componentClass}
            >
                <div className="Video-wrapper">
                    <div className="Video-inner">

                        <div
                            data-plyr-provider="youtube"
                            data-plyr-embed-id={this.props.video}

                            ref={(el) => this.registerElement(el)}
                        />

                    </div>
                </div>
            </figure>
        );
    }
}

export default VideoPlayer;
