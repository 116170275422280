import Reflux        from 'reflux';
import {MouseActions} from "./MouseStoreActions";


export class MouseStore extends Reflux.Store {

    constructor(initialState = {}) {
        super();

        this.state = {
            isHovering: false,
        };

        this.listenables = MouseActions;
    }

    toggleHovering(isHovering) {
        this.setState({isHovering});
    }
}