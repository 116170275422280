/**
 *
 * @author Fractal-IT
 */
import React from 'react';
import {Route, Redirect, withRouter, matchPath} from 'react-router-dom';

import Scrollbar from 'react-smooth-scrollbar';

/*** COMPONENTS ***/

import ComponentBase from './components/base/ComponentBase';
import RoutesTransitions from './RoutesTransitions';


class RoutesBase extends ComponentBase {

    didMount() {
        const {scrollbar} = this.$container;
        this.props.getScrollbar && this.props.getScrollbar(scrollbar);
    }

    willUnmount() {

    }

    render() {
        let language = this.state.language;
        let isMobile = this.props.isMobile;

        let currentMatchPath = matchPath(this.props.location.pathname, {
            path: "/" + this.state.language + "/:themeSlug/:chapterSlug",
            exact: true,
            strict: false
        });

        let contentClass = "Content";
        if(currentMatchPath) {
            contentClass += " Content--player";
        }

        return (

            <Route render={({ location }) => (

                <Scrollbar
                    className={contentClass}
                    ref={c => this.$container = c}
                    damping={isMobile ? 0.075 : 0.1}
                >

                    <Route exact path="/" render={() => (
                        <Redirect to={"/" + language} />
                    )}/>

                    <RoutesTransitions
                        location={location}
                        history={this.props.history}
                    />

                </Scrollbar>

            )} />

        );
    }
}

export default withRouter(RoutesBase);
